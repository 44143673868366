<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseSlugs.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseSlugs.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseSlugs = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <h5 class="mb-0">
            {{ $t('Navigation.Channel/StoreList') }}
          </h5>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto px-2">
          <CRow>
            <CCol>
              <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
                <CIcon name="cil-filter"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
                {{ $t('Order.Filter.DataTime') }}:
                {{ SearchFilter.startTime && SearchFilter.endTime ? `${$dayjs(SearchFilter.startTime).format('YYYY-MM-DD HH:mm:ss')} ~ ${$dayjs(SearchFilter.endTime).format('YYYY-MM-DD HH:mm:ss')}` : '所有時間區間' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Slug')">
                {{ $t('Post/List.Slug') }}: {{ SearchFilter.slug.join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
                {{ $t('Post/List.Name') }}: {{ SearchFilter.name || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Status')">
                {{ $t('Post/Detail.Status') }}: {{ SearchFilter.status ? $t('Post/List.StatusOptions.' + SearchFilter.status) : '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetFilter()">
                <CIcon size="sm" name="cil-x"/>
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
              id="SinglePageList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-Post-' + item.Slug" :value="item.Slug" v-model="ChooseSlugs">
                </label>
              </td>
            </template>
            <template #Name="{item}">
              <td>
                <a href="#" class="btn-link text-info" @click="HandleExternalLink(item.Slug)">
                  {{ item.Name }}
                </a>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                  {{ $t('Post/Detail.StatusOptions.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton color="info" size="sm" class="mr-1" @click="$router.push(`/channel/store/detail/${item.Slug}`)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/> {{ $t('Global.Edit') }}
                </CButton>
                <CButton color="secondary" size="sm" class="mr-1" v-c-tooltip="$t('Product/List.ViewListPage')" @click="HandleExternalLink(item.Slug)">
                  <CIcon name="cil-window-restore" class="c-icon-custom-size mr-1" />
                </CButton>
                <CButton color="secondary" size="sm" class="mr-1" v-c-tooltip="$t('Product/List.Duplicate')" @click="Duplicate(item.Slug)">
                  <CIcon name="cil-copy" class="c-icon-custom-size mr-1" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="ChangePage()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit" @update:show="ChangePage()">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.startTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.endTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.endTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.startTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" :label="$t('Post/List.Name')" v-model="SearchFilter.name" horizontal/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Slug'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Post/List.Slug') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.slug"
            v-model="SearchFilter.slug"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.slug"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Status'" horizontal :label="$t('Post/Detail.Status')" :options="StatusOptions" v-model="SearchFilter.status" :value.sync="SearchFilter.status" :placeholder="$t('Global.PleaseSelect')" />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
  "meta": {
    "label": "門市列表"
  }
}
</route>

<script>
export default {
  name: 'SinglePageList',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data () {
    return {
      List: [],
      AlertModel: false,
      BulkEditModel: false,
      BulkEditData: {
        Status: true,
        Brand: '',
        Amount: {
          Suggest: '',
          Actual: '',
          Cost: ''
        }
      },
      SearchFilter: {
        startTime: null,
        endTime: null,
        name: '',
        slug: [],
        status: ''
      },
      ChooseSlugs: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'nto',
      Loading: false,
      Submit: false,
      ShowFilter: 'All',
      FilterModel: false,
      noItemsView: {
        noResults: this.$t('Post/List.NoResults'),
        noItems: this.$t('Post/List.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Name', label: this.$t('Channel/Store.Name') },
        { key: 'Slug', label: this.$t('Channel/Store.Slug') },
        { key: 'Status', label: this.$t('Product/SinglePage.Status') },
        { key: 'Action', label: '' }
      ]
    },
    StatusOptions () {
      return Object.keys(this.$t('Post/Detail.StatusOptions')).filter(item => parseInt(item) < 2).map(status => {
        return {
          value: parseInt(status),
          label: this.$t('Post/Detail.StatusOptions')[status]
        }
      })
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
      ]).then(() => {
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'page':
            case 'perPage':
            case 'order':
              break
            case 'startTime':
            case 'endTime':
              this.SearchFilter[param] = parseInt(this.$route.query[param]) * 1000
              break
            case 'name':
            case 'status':
              this.SearchFilter[param] = this.$route.query[param]
              break
            default:
              this.SearchFilter[param] = this.$route.query[param].split(',')
              break
          }
        })
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order,
        taxonomy: 'MENU'
      }
      Object.keys(this.SearchFilter).forEach((param) => {
        RequestQuery[param] = this.$route.query[param]
      })
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      if (this.SearchFilter.startTime) query.startTime = this.$dayjs(this.SearchFilter.startTime).unix()
      if (this.SearchFilter.endTime) query.endTime = this.$dayjs(this.SearchFilter.endTime).unix()
      if (this.SearchFilter.name) query.name = this.SearchFilter.name
      if (this.SearchFilter.status) query.status = this.SearchFilter.status.toString()
      if (this.SearchFilter.slug.length > 0) query.slug = this.SearchFilter.slug.join(',')
      this.FilterModel = false
      this.$router.replace({
        path: '/channel/store/list',
        query
      }).then(() => {
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/delete',
        method: 'post',
        data: {
          slug: this.ChooseSlugs,
          taxonomy: 'MENU'
        }
      }).then(() => {
        this.Init()
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Channel/Store/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Post/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseSlugs = this.List.map(data => {
          return data.Slug
        })
      } else {
        this.CheckAllValue = false
        this.ChooseSlugs = []
      }
    },
    HandleExternalLink(slug) {
      return window.open('//' + this.$store.state.user.permission.Domain + `/menu/${slug}`, '_blank')
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.ChooseSlugs = []
    },
    OpenFilter(type) {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        startTime: null,
        endTime: null,
        categories: [],
        categoryName: '',
        tags: [],
        name: '',
        slug: []
      }
      this.ChooseSlugs = []
      this.$router.replace({
        path: '/channel/store/list'
      }).then(() => {
        this.Init()
      })
    },
    Duplicate(slug) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/duplicate',
        method: 'get',
        params: {
          slug,
          taxonomy: 'MENU'
        }
      }).then(() => {
        this.Loading = false
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/SuccessCopySalePage'),
          type: 'success'
        })
      }).catch((error) => {
        this.Loading = false
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Product/FailCopySalePage') + ' :' + error.msg,
          type: 'error'
        })
      })
    }
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#SinglePageList thead th:first-child {
  text-align: center;
}
#SinglePageList tbody td:first-child {
  vertical-align: middle;
}
</style>
