<template>
  <CModal class="ActionModel" size="xl" :show.sync="Toggle" :title="$t('Offer.ChooseProduct')" :centered="true" @update:show="HandleHideModal()">
    <multiselect
      id="SearchFilter.Site"
      class="mb-3"
      v-model="SearchFilter.Site"
      :optionHeight="24"
      label="label"
      track-by="value"
      placeholder="請選擇資料集"
      :deselectLabel="$t('Global.DeselectLabel')"
      :selectedLabel="$t('Global.SelectedLabel')"
      :options="$store.getters.collectionList"
      :multiple="false"
      :taggable="false"
      :showNoResults="false"
      :showNoOptions="false"
      @input="Init()"
    >
      <template #noOptions>
        {{ $t('Global.NoOptions') }}
      </template>
    </multiselect>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="12" lg>
            <CInput :label="$t('Product/List.Name')" v-model="SearchFilter.Name" placeholder="請輸入商品關鍵字以搜尋商品..." class="mb-0" />
          </CCol>
          <CCol v-if="ChooseProductType === 'Default' || ChooseProductType === 'TargetItem'" md="12" lg>
            <label for=""> {{ $t('Product/List.Categories') }}</label>
            <multiselect
              id="SearchFilter.SelectCategories"
              v-model="SearchFilter.SelectCategories"
              :optionHeight="24"
              label="label"
              track-by="value"
              :tag-placeholder="$t('Global.EnterToAdd')"
              :placeholder="''"
              :deselectLabel="$t('Global.DeselectLabel')"
              :selectedLabel="$t('Global.SelectedLabel')"
              :options="CategoryList"
              :multiple="true"
              :taggable="true"
              @tag="GetList()"
            >
              <template #noOptions>
                {{ $t('Global.NoOptions') }}
              </template>
            </multiselect>
<!--            <CRow form class="form-group">-->
<!--              <CCol tag="label" sm="3" class="col-form-label">-->
<!--                {{ $t('Product/List.Categories') }}-->
<!--              </CCol>-->
<!--              <CCol sm="9">-->
<!--                <multiselect-->
<!--                  id="SearchFilter.SelectCategories"-->
<!--                  v-model="SearchFilter.SelectCategories"-->
<!--                  :optionHeight="24"-->
<!--                  label="label"-->
<!--                  track-by="value"-->
<!--                  :tag-placeholder="$t('Global.EnterToAdd')"-->
<!--                  :placeholder="''"-->
<!--                  :deselectLabel="$t('Global.DeselectLabel')"-->
<!--                  :selectedLabel="$t('Global.SelectedLabel')"-->
<!--                  :options="CategoryList"-->
<!--                  :multiple="true"-->
<!--                  :taggable="true"-->
<!--                  @tag="GetList()"-->
<!--                >-->
<!--                  <template #noOptions>-->
<!--                    {{ $t('Global.NoOptions') }}-->
<!--                  </template>-->
<!--                </multiselect>-->
<!--              </CCol>-->
<!--            </CRow>-->
          </CCol>
          <CCol col="12" class="mt-3">
            <CButton @click="GetList()" color="success" class="px-4">
              {{ $t('Global.Search') }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          :loading="Loading"
          id="ProductList"
          :items="List"
          :fields="Field"
          :noItemsView="noItemsView"
          responsive
          hover
        >
          <template #Checkbox-header>
            <div class="text-center">
              <label class="mb-0">
                <input v-if="!Single" type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </div>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input :type="(Single === true ? 'radio' : 'checkbox')" :id="'Checkbox-List-' + item.SeriesNum" :value="item.SeriesNum" v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #Cover="{item}">
            <td>
              <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
            </td>
          </template>
          <template #Status="{item}">
            <td>
              <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                {{ $t('Product.Status.' + item.Status) }}
              </CBadge>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination
          :activePage.sync="Pagination.Page"
          :pages.sync="Pagination.TotalPages"
          @update:activePage="Init()"
        />
      </CCardFooter>
    </CCard>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton id="ChooseProduct" @click="Choose()" color="success" block>
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: {
    Single: Boolean,
    ChooseProductType: {
      type: String,
      default: 'Default'
    },
    Toggle: Boolean,
    ChooseSaleMode: {
      type: Array,
      default() {
        return []
      }
    },
    Action: String,
  },
  name: "ProductListComponent",
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data() {
    return {
      List: [],
      SearchFilter: {
        Site: {},
        SelectCategories: [],
        SelectCategoriesName: '',
        SeriesNum: [],
        CustomSeriesNum: [],
        Name: '',
      },
      CategoryList: [],
      ShowFilter: 'All',
      FilterModel: false,
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'create_nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'CustomSeriesNum', label: this.$t('Product/List.CustomSeriesNum') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Status', label: this.$t('Product/List.Status') }
      ]
    }
  },
  mounted() {
    this.SearchFilter.Site = {
      label: this.$store.getters.collection.label || '',
      value: this.$store.getters.collection.value || ''
    }
    this.Init().catch((err) => {
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
        this.GetCategories()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
        site: this.SearchFilter.Site.value
      }
      if (this.SearchFilter.CustomSeriesNum.length > 0) {
        RequestQuery.customSeriesNum = this.SearchFilter.CustomSeriesNum.join(',')
      }
      if (this.SearchFilter.SeriesNum.length > 0) {
        RequestQuery.seriesNum = this.SearchFilter.SeriesNum.join(',')
      }
      if (this.SearchFilter.Name !== '') {
        RequestQuery.name = this.SearchFilter.Name
      }
      if (Array.isArray(this.SearchFilter.SelectCategories) && this.SearchFilter.SelectCategories.length > 0) {
        RequestQuery.category = this.SearchFilter.SelectCategories.map(item => item.value).join(',')
      }
      switch (this.ChooseProductType) {
        case 'Gift':
        case 'SelectGift':
          RequestQuery.taxonomy = 'Gift'
          break
        case 'ExtraItems':
        case 'TargetExtraItems':
          RequestQuery.taxonomy = 'ExtraItems'
          break
      }
      if (this.ChooseSaleMode) {
        RequestQuery.saleMode = this.ChooseSaleMode.join(',')
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetCategories() {
      let RequestQuery = {
        site: this.SearchFilter.Site.value
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.CategoryList = data.list.map((category) => {
          return {label: category.Name, value: category.Slug}
        })
      }).catch((err) => {
        throw err
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.SeriesNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    Choose() {
      this.$emit('ChooseProduct', this.List.filter(data => {
        return this.ChooseIndex.includes(data.SeriesNum)
      }))
      this.HandleHideModal()
    },
    HandleHideModal () {
      this.$emit('update:Toggle', false)
    }
  }
}
</script>

