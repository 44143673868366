<template>
  <main>
    <CAlert :show="NewUI" class="d-flex justify-content-between align-items-center" color="info" :fade="false">
      <div class="d-flex align-items-center">
        <CBadge class="mr-1" color="info" v-c-tooltip="{content: $t('Global.PreviewDescription'), placement: 'bottom'}">{{ $t('Global.Preview') }}</CBadge>
        現在可於新版會員列表以更多條件篩選會員名單，並建立訊息通知。
      </div>
      <div>
        <CButton color="info" size="sm" class="mr-2" @click="RedirectToECRM()">
          前往新版會員列表
        </CButton>
      </div>
    </CAlert>
    <CForm>
      <CCard>
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseUsers.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseUsers.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseUsers = [];CheckAllValue = false">
              {{ $t('Global.Cancel') }}
            </CButton>
            <CButton v-if="Features.includes('SendEmailMessage')" color="info" size="sm" class="mr-2" @click="BulkSendEmail()">{{ $t('Organization.SendEmail') }}</CButton>
            <CButton v-if="Features.includes('SendSMSMessage') && NotifyFeatures.includes('SMS')" color="dark" size="sm" class="mr-2" @click="BulkSendSMSMessage()">{{ $t('Organization.SendSMSMessage') }}</CButton>
            <CButton v-if="Features.includes('DiscountTicket')" color="warning" size="sm" class="mr-2" @click="IssueTicket()">{{ $t('Organization/Member.DistributeTicket') }}</CButton>
            <CButton v-if="Permission.includes('W')" color="primary" size="sm" class="mr-2" @click="AddTagModal = true">{{ $t('Organization/Member.EditTags') }}</CButton>
            <CButton v-if="Permission.includes('R')" color="info" size="sm" class="mr-2" @click="Export()">{{ $t('Global.ExportToGoogleSheet') }}</CButton>
            <CButton v-if="Permission.includes('R')" color="info" size="sm" class="mr-2" @click="ExportCSV()">{{ $t('Global.ExportToCSV') }}</CButton>
            <CButton v-if="Features.includes('DeleteMember')" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Organization/ListMember') }}</h5>
            </CCol>
            <CCol col="6" class="text-md-right">
              <CButton v-if="Permission.includes('W')" color="success" size="sm" class="mr-2" @click="OpenCreateUserModel()">
                <CIcon size="sm" name="cil-plus" class="mr-1" />{{ $t('Navigation.Organization/AddMember') }}
              </CButton>
              <CDropdown size="sm" color="danger" placement="bottom-start" class="d-inline-block">
                <template #toggler-content>
                  <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Organization.MemberAction') }}
                </template>
                <CDropdownHeader v-if="Permission.includes('W')">
                  {{ $t('Organization.BulkImportMember') }}
                </CDropdownHeader>
                <CDropdownItem v-if="Permission.includes('W')" @click="BulkImportMember('GoogleSheets')">
                  {{ $t('Organization.BulkImportMemberGoogleSheet') }}
                </CDropdownItem>
                <template v-if="Permission.includes('R')">
                  <CDropdownHeader>
                    {{ $t('Global.Export') }}
                  </CDropdownHeader>
                  <CDropdownItem @click="Export('GoogleSheets')">
                    {{ $t('Global.ExportToGoogleSheet') }}
                  </CDropdownItem>
                  <CDropdownItem @click="ExportCSV()">
                    {{ $t('Global.ExportToCSV') }}
                  </CDropdownItem>
                </template>
              </CDropdown>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto px-2">
          <CRow class="text-nowrap">
            <CCol col="12">
              <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
                <CIcon name="cil-filter"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('CreateTime')">
                {{ $t('Organization.CreateTime') }}: {{ SearchFilter.StartTime && SearchFilter.EndTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD') + ' ~ ' + $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD') : '全部區間' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 text-truncate" @click="OpenFilter('Uid')" style="max-width:200px">
                {{ $t('Organization.Uid') }}: {{ SearchFilter.Uid.join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 text-truncate" @click="OpenFilter('Email')" style="max-width:200px">
                {{ $t('Organization.Email') }}: {{ SearchFilter.Email.join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Class')">
                {{ $t('Organization.Class') }}: {{ SearchFilter.Class !== '' ? $t('Organization.ClassOptions.' + SearchFilter.Class) : '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
                {{ $t('Organization.Name') }}: {{ SearchFilter.Name || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Mobile')">
                {{ $t('Organization.Mobile') }}: {{ SearchFilter.Mobile || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Country')">
                {{ $t('Organization.Country') }}: {{ SearchFilter.Country || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Tags')">
                {{ $t('Organization.Tags') }}: {{ SearchFilterCondition.Tags !== 'Default' ? `${$t('Global.ConditionOptions.' + SearchFilterCondition.Tags)}` : '' }}{{ SearchFilter.Tags.join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton v-if="Features.includes('MemberLevel')" size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Level')">
                {{ $t('Organization/Member.Level') }}: {{ SearchFilterCondition['Level.ID'] !== 'Default' ? `${$t('Global.ConditionOptions.' + SearchFilterCondition['Level.ID'])}` : '' }} {{ SearchFilter.Level.map(item => item.Name).join(',') || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('BirthdayMonth')">
                {{ $t('Organization/Member.BirthdayMonth') }}: {{ SearchFilter.BirthdayMonth ? SearchFilter.BirthdayMonth + '月' : '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('TotalRealAmount')">
                {{ $t('Organization/Member.TotalRealAmount') }}: {{ '$' + (SearchFilter.TotalRealAmountMin || 0) + ' ~ ' + (SearchFilter.TotalRealAmountMax || 'Max')}}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('WalletBonus')">
                {{ $t('Organization/Member.WalletBonus') }}: {{ '$' + (SearchFilter.WalletBonusMin || 0) + ' ~ ' + (SearchFilter.WalletBonusMax || 'Max')}}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('PerPage')">
                {{ $t('Order.Filter.PerPage') }}: {{ Pagination.PerPage }} {{ $t('Global.OrderUnit') }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Sort')">
                {{ $t('Organization/Member.Sort') }}: {{ $t('Organization/Member.SortType.' + this.Order) || '--' }}
                <CIcon size="sm" class="ml-1" name="cil-pencil"/>
              </CButton>
              <CButton size="sm" color="danger" shape="pill" @click="ResetFilter()">
                <CIcon size="sm" name="cil-x"/>
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardHeader class="w-100 overflow-auto">
          <CRow class="text-nowrap" style="min-width: 900px;">
            <CCol col>
              <CCallout color="success" class="my-1">
                <small class="text-muted">會員總計 <i v-c-tooltip="{content: '當前篩選條件的會員總計',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
                <strong class="h4">{{ Pagination.TotalItems || 0 }}</strong>
              </CCallout>
            </CCol>
            <CCol col>
              <CCallout color="info" class="my-1">
                <small class="text-muted">會員消費金額總計 <i v-c-tooltip="{content: '消費金額不包含運費及手續費',placement: 'right'}" class="fas fa-question-circle text-info" /></small><br>
                <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalStatistics.TotalRealAmount || 0) }}</strong>
              </CCallout>
            </CCol>
            <CCol col>
              <CCallout color="danger" class="my-1">
                <small class="text-muted">會員購物金總計</small><br>
                <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(TotalStatistics.TotalMemberBonus || 0) }}</strong>
              </CCallout>
            </CCol>
            <CCol col>
<!--              <CCallout color="warning" class="my-1">-->
<!--                <small class="text-muted">會員訂單金額總計</small><br>-->
<!--                <strong class="h4">{{ $store.state.currency + '$' }}{{ TotalStatistics.TotalOrderAmount || 0 }}</strong>-->
<!--              </CCallout>-->
            </CCol>
            <CCol col />
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
              id="MemberList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              addTableClasses="small text-nowrap"
              responsive
              hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-User-' + item.Uid" :value="item.Uid" v-model="ChooseUsers">
                </label>
              </td>
            </template>
            <template #Name="{item}">
              <td>
                <p class="font-weight-bold mb-0">
                  <CIcon v-c-tooltip="$t('Logistics.CountryOptions.' + (item.Country || 'TW'))" class="mr-1" :name="'cif-' + (typeof item.Country === 'string'? item.Country.toLowerCase() : 'tw')" />{{ item.Name }}
                </p>
                <div v-if="Array.isArray(item.Tags)" class="Tags mt-1">
                  <CBadge v-for="(Tag, Index) in item.Tags" :key="Tag + Index" class="mr-1" color="info">
                    {{ Tag }}
                  </CBadge>
                </div>
              </td>
            </template>
            <template #Contact="{item}">
              <td>
                <b>電子信箱: </b>{{ item.Email || '--' }}<br>
                <b>行動電話: </b>{{ item.Mobile || '--' }}
              </td>
            </template>
            <template #disabled="{item}">
              <td>
                <CBadge :color="(item.disabled === false ? 'success' : 'danger')">
                  {{ $t('Organization.' + (item.disabled === false ? 'Enable' : 'Disable')) }}
                </CBadge>
              </td>
            </template>
            <template #emailVerified="{item}">
              <td>
                <CBadge :color="(item.emailVerified === true ? 'success' : 'danger')">
                  {{ $t('Organization.' + (item.emailVerified === true ? 'Verified' : 'Unverified')) }}
                </CBadge>
              </td>
            </template>
            <template #creationTime="{item}">
              <td>
                <b>{{ $t('Organization.CreateTime') }}: </b>{{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}<br>
                <b>{{ $t('Organization.LastLoginTime') }}: </b>{{ item.metadata.lastSignInTime ? $dayjs(item.metadata.lastSignInTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              </td>
            </template>
            <template #lastSignInTime="{item}">
              <td>
                {{ item.metadata.lastSignInTime ? $dayjs(item.metadata.lastSignInTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              </td>
            </template>
            <template #Bonus="{item}">
              <td class="text-center">
                <a href="#" class="text-info" @click="GetUserBonusRecord(item)">{{ item.Bonus.Value }}</a>
              </td>
            </template>
            <template #Level="{item}">
              <td>
                <b>目前等級</b>: {{ item.Level ? item.Level.Name : '--' }}<br>
                <b>累計消費金額</b>: ${{ $amount.format(item.Level.TotalRealAmount || 0) }}<i v-c-tooltip="{content: '會員所有已付款的訂單累計消費金額，已扣除優惠且不包含運費',placement: 'right'}" class="fas ml-1 fa-question-circle text-info" />
              </td>
            </template>
            <template #Class="{item}">
              <td>
                <CBadge :color="(item.Class === 'A' ? 'info' : 'secondary')">
                  {{ $t('Organization.ClassOptions.' + item.Class) }}
                </CBadge>
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton v-if="Permission.includes('W')" v-c-tooltip="$t('Organization/Member.UpdateUser')" color="info" variant="ghost" shape="pill" size="sm" @click="GetUser(item.Uid)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size"/>
                </CButton>
                <CButton v-if="Features.includes('Bonus')" v-c-tooltip="$t('Organization/Member.DistributeBonus')" color="success" variant="ghost" shape="pill" size="sm" @click="OpenDistributeBonusModal(item)">
                  <CIcon name="cil-money" class="c-icon-custom-size"/>
                </CButton>
                <CButton v-if="Features.includes('DiscountTicket')" v-c-tooltip="$t('Organization/Member.DistributeTicket')" color="danger" variant="ghost" shape="pill" size="sm" @click="ChooseUsers.push(item.Uid);IssueTicket()">
                  <CIcon name="cil-tag" class="c-icon-custom-size"/>
                </CButton>
                <CButton v-if="Features.includes('SendEmailMessage') && (item.Email !== '')" v-c-tooltip="$t('Organization.SendEmail')" color="warning" variant="ghost" shape="pill" size="sm" @click="ChooseUsers.push(item.Uid);BulkSendEmail()">
                  <CIcon name="cil-envelope-closed" class="c-icon-custom-size"/>
                </CButton>
                <CButton v-if="Features.includes('SendSMSMessage') && (item.Mobile !== '')" v-c-tooltip="$t('Organization.SendSMSMessage')" color="dark" variant="ghost" shape="pill" size="sm" @click="ChooseUsers.push(item.Uid);BulkSendSMSMessage()">
                  <CIcon name="cil-short-text" class="c-icon-custom-size"/>
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
            :activePage.sync="Pagination.Page"
            :pages.sync="Pagination.TotalPages"
            @update:activePage="GetList()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="CreateUserModel" class="ActionModel" :show.sync="CreateUserModel" size="xl" :centered="true" :closeOnBackdrop="!Submit">
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Organization/Member.' + (Action === 'Update' ? 'UpdateUser' : 'CreateUser')) }}</h5>
          <CButtonClose @click="CreateUserModel = false"/>
        </header>
      </template>
      <CTabs :active-tab="0" :key="User.uid" fade addTabsWrapperClasses="tab-info">
        <CTab :title="$t('Organization/Member.Config')">
          <CRow class="mt-3">
            <CCol lg="6">
              <CInput id="uid" v-if="Action === 'Update'" horizontal v-model="User.uid" :label="$t('Organization.Uid')"
                      disabled/>
              <CInput id="email" horizontal v-model="User.email" :label="$t('Organization.Email')" />
              <CInput id="displayName" horizontal v-model="User.displayName" :label="$t('Organization.Name')"/>
              <CInput id="photoURL" name="photoURL" horizontal v-model="User.photoURL" :label="$t('Organization.Avatar')"/>
            </CCol>
            <CCol lg="6">
              <CInput v-if="Action === 'Update'" horizontal v-model="UserData.ClientID" :label="$t('Organization.ClientID')"
                      disabled />
              <CInput id="password" name="password" horizontal :type="(PasswordVisible ? 'text' : 'password')" v-model="User.password"
                      :label="$t('Organization.Password')">
                <template #append>
                  <CButton color="light" @click="PasswordVisible = !PasswordVisible">
                    <CIcon class="my-0" :name="(PasswordVisible ? 'cil-lock-unlocked' : 'cil-lock-locked')" size="sm"/>
                  </CButton>
                </template>
              </CInput>
              <CRow v-if="Action === 'Update'" form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.EmailVerified') }}
                </CCol>
                <CCol sm="9" class="pt-2">
                  <CInputRadioGroup v-model="User.emailVerified" size="sm" :options="[{value: true, label: $t('Organization.Verified')},{value: false, label: $t('Organization.Unverified')}]" :checked.sync="User.emailVerified" :custom="true" inline/>
                  <!--                  <CBadge :color="(User.emailVerified === true ? 'success' : 'danger')">-->
                  <!--                    {{ User.emailVerified === true ? $t('Organization.Verified') : $t('Organization.Unverified') }}-->
                  <!--                  </CBadge>-->
                </CCol>
              </CRow>
              <CRow v-if="Action === 'Update'" form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Status') }}
                </CCol>
                <CCol sm="9" class="pt-2">
                  <CInputRadioGroup v-model="User.disabled" size="sm" :options="[{value: false, label: $t('Organization.Enable')}, {value: true, label: $t('Organization.Disable')}]" :checked.sync="User.disabled" :custom="true" inline/>
                  <!--                  <CBadge :color="(User.disabled === false ? 'success' : 'danger')">-->
                  <!--                    {{ User.disabled === false ? $t('Organization.Enable') : $t('Organization.Disable') }}-->
                  <!--                  </CBadge>-->
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('Organization/Member.Info')">
          <CRow class="mt-3">
            <CCol lg="6">
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Class') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="ClassOptions" v-model="UserData.Class" :value.sync="UserData.Class" :placeholder="$t('Global.PleaseSelect')"/>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Sex') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="SexOptions" v-model="UserData.Sex" :value.sync="UserData.Sex" :placeholder="$t('Global.PleaseSelect')"/>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Birthday') }}
                </CCol>
                <CCol sm="9">
                  <v-date-picker v-model="UserData.Birthday" mode="date">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="form-control"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </CCol>
              </CRow>
              <CInput horizontal v-model="UserData.Phone" :label="$t('Organization.Phone')" @blur="CheckPhone('Phone')">
                <template #prepend-content>
                  <CIcon class="mr-1" :name="'cif-' + (typeof UserData.Country === 'string'? UserData.Country.toLowerCase() : 'tw')" /> {{ $t('Global.CountryCallPrefixOptions.' + (UserData.Country || 'TW')) }}
                </template>
              </CInput>
              <CInput horizontal v-model="UserData.Mobile" :label="$t('Organization.Mobile')" @blur="CheckPhone('Mobile')">
                <template #prepend-content>
                  <CIcon class="mr-1" :name="'cif-' + (typeof UserData.Country === 'string'? UserData.Country.toLowerCase() : 'tw')" /> {{ $t('Global.CountryCallPrefixOptions.' + (UserData.Country || 'TW')) }}
                </template>
              </CInput>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Tags') }}
                </CCol>
                <CCol sm="9">
                  <multiselect
                    v-model="UserData.Tags"
                    :tag-placeholder="$t('Global.EnterToAdd')"
                    :placeholder="$t('Global.PleaseKeyIn')"
                    :deselectLabel="$t('Global.DeselectLabel')"
                    :selectedLabel="$t('Global.SelectedLabel')"
                    :options="Tags"
                    :multiple="true"
                    :taggable="true"
                    @tag="(prop) => UserData.Tags.push(prop)">
                    <template #noOptions>
                      {{ $t('Global.NoOptions') }}
                    </template>
                  </multiselect>
                </CCol>
              </CRow>
              <CTextarea horizontal v-model="UserData.Note" row="3" :label="$t('Organization.Note')"/>
            </CCol>
            <CCol lg="6">
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Country') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="CountryOptions" v-model="UserData.Country" :value.sync="UserData.Country" :placeholder="$t('Global.PleaseSelect')"/>
                </CCol>
              </CRow>
              <template v-if="(UserData.Country === 'TW')">
                <CSelect horizontal :label="$t('Organization.City')" :options="City" v-model="UserData.City" :value.sync="UserData.City" :placeholder="$t('Global.PleaseSelect')"/>
                <CSelect horizontal :label="$t('Organization.Area')" :options="Area" v-model="UserData.Area" :value.sync="UserData.Area" @change="SetZip()" :placeholder="$t('Global.PleaseSelect')"/>
              </template>
              <template v-else>
                <CInput horizontal v-model="UserData.City" :label="$t('Organization.City')" />
                <CInput horizontal v-model="UserData.Area" :label="$t('Organization.Area')" />
              </template>
              <CInput horizontal v-model="UserData.ZipCode" :label="$t('Organization.ZipCode')" :disabled="(UserData.Country === 'TW')" />
              <CInput horizontal v-model="UserData.Address" :label="$t('Organization.Address')" />
              <CInput horizontal v-model="UserData.FacebookOpenID" :label="$t('Organization.FacebookOpenID')"/>
              <CInput horizontal v-model="UserData.LineOpenID" :label="$t('Organization.LineOpenID')"/>
            </CCol>
          </CRow>
        </CTab>
        <CTab class="d-none" :title="$t('Organization/Member.Other')">
          <CRow class="mt-3">
            <CCol lg="6">
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization.Groups') }}
                </CCol>
                <CCol sm="9">
                  <multiselect
                      v-model="UserData.Groups"
                      :options="MemberGroup"
                      :optionHeight="34"
                      :placeholder="$t('Global.PleaseSelect')"
                      :selectLabel="$t('Global.SelectLabel')"
                      :selectedLabel="$t('Global.SelectedLabel')"
                      :deselectLabel="$t('Global.DeselectLabel')"
                      :multiple="true"
                      :taggable="true"
                      @tag="(tag) => UserData.Groups.push(tag)"
                  >
                    <template #noOptions>
                      {{ $t('Global.NoOptions') }}
                    </template>
                  </multiselect>
                </CCol>
              </CRow>
            </CCol>
            <CCol lg="6">
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  Collections
                </CCol>
                <CCol sm="9">
                  <multiselect
                    v-model="UserData.Collections"
                    :optionHeight="34"
                    :placeholder="$t('Global.PleaseSelect')"
                    :selectLabel="$t('Global.SelectLabel')"
                    :selectedLabel="$t('Global.SelectedLabel')"
                    :deselectLabel="$t('Global.DeselectLabel')"
                    :multiple="true"
                    :taggable="true"
                    @tag="(tag) => UserData.Collections.push(tag)"
                  >
                    <template #noOptions>
                      {{ $t('Global.NoOptions') }}
                    </template>
                  </multiselect>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('Organization/Member.OrderHistory')">
          <CDataTable
            id="OrderHistory"
            addTableClasses="small text-nowrap"
            :items="OrderHistory"
            :fields="OrderHistoryField"
            :loading="Loading"
            :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
            responsive
            hover
            striped
          >
            <template #OrderNum="{item}">
              <td>
                <a :href="`/order/detail/${item.OrderNum}`" target="_blank">{{item.OrderNum}}</a>
              </td>
            </template>
            <template #Origin="{item}">
              <td>
                {{ $t('Order.OriginOptions.' + item.OriginType) }}<span v-if="item.OriginType !== 'Default'">({{ item.OriginPage ? item.OriginPage : '' }})</span>
              </td>
            </template>
            <template #TotalAmount="{item}">
              <td>
                {{item.TotalAmount}}
              </td>
            </template>
            <template #RealAmount="{item}">
              <td>
                {{item.RealAmount}}
              </td>
            </template>
            <template #PaymentStatus="{item}">
              <td>
              <CBadge :color="StatusClassOption[item.Status.Payment]">
                {{ $t('Order.PaymentStatusList.' + item.Status.Payment) }}
              </CBadge>
              </td>
            </template>
            <template #LogisticsStatus="{item}">
              <td>
              <CBadge :color="StatusClassOption[item.Status.Logistics]">
                {{ $t('Order.LogisticsStatusList.' + item.Status.Logistics) }}
              </CBadge>
              </td>
            </template>
            <template #OrderStatus="{item}">
              <td>
              <CBadge :color="StatusClassOption[item.Status.Order]">
                {{ $t('Order.OrderStatusList.' + item.Status.Order) }}
              </CBadge>
              </td>
            </template>
            <template #CreateTime="{item}">
              <td>
                {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
          </CDataTable>
          <CCardFooter class="pb-0">
            <CPagination :activePage.sync="OrderPagination.Page" :pages.sync="OrderPagination.TotalPages"
              @update:activePage="ChangeOrderPage()"/>
          </CCardFooter>
        </CTab>
        <CTab v-if="UserData.Level" :title="$t('Organization/Member.LevelInfo')">
          <h5>等級設定</h5>
          <hr>
          <CRow class="mt-3">
            <CCol lg="6">
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization/Member.Level') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="MemberLevelListOptions" v-model="UserData.Level.ID" :value.sync="UserData.Level.ID" :placeholder="$t('Global.PleaseSelect')"/>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization/Member.LevelLogRecord') }}
                </CCol>
                <CCol sm="9">
                  <CSelect class="mb-0" :options="LevelChangeLogOptions" v-model="UserData.Level.LevelChangeID" :value.sync="UserData.Level.LevelChangeID" :placeholder="$t('Global.PleaseSelect')"/>
                </CCol>
              </CRow>
              <CRow form class="form-group">
                <CCol tag="label" sm="3" class="col-form-label">
                  {{ $t('Organization/Member.IsRenew') }}
                </CCol>
                <CCol sm="9" class="pt-2">
                  <CInputRadioGroup v-model="UserData.Level.IsRenew" :options="[{value: false, label: $t('Organization/Member.RenewOptions.New')},{value: true, label: $t('Organization/Member.RenewOptions.Renew')}]" :checked.sync="UserData.Level.IsRenew" :custom="true" inline/>
                </CCol>
              </CRow>
            </CCol>
            <CCol lg="6">
              <CInput horizontal disabled v-model="UserData.Level.TotalRealAmount" :label="$t('Organization/Member.TotalRealAmount')">
                <template #prepend-content>
                  <span>$</span>
                </template>
              </CInput>
              <CInput horizontal disabled v-model="UserData.Level.TotalOrderAmount" :label="$t('Organization/Member.TotalOrderAmount')">
                <template #prepend-content>
                  <span>$</span>
                </template>
              </CInput>
              <CInput horizontal disabled v-model="UserData.Level.TotalOrderItems" :label="$t('Organization/Member.TotalOrderItems')">
                <template #prepend-content>
                  <span>$</span>
                </template>
              </CInput>
            </CCol>
          </CRow>
          <h5 class="my-4">變更紀錄</h5>
          <CDataTable
            id="LevelChangeLog"
            addTableClasses="small text-nowrap"
            :items="LevelChangeLog"
            :fields="LevelChangeLogField"
            :loading="Loading"
            :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
            responsive
            hover
            striped
          >
            <template #CreateTime="{item}">
              <td>
                {{ $dayjs(item.CreateTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #FullAmount="{item}">
              <td>
                ${{ item.FullAmount || '0' }}
              </td>
            </template>
            <template #RenewAmount="{item}">
              <td>
                ${{ item.RenewAmount || '0' }}
              </td>
            </template>
            <template #CurrentTotalAmount="{item}">
              <td>
                {{ !item.IsRenew && parseInt(item.ID) !== 0 ? '$' + (item.CurrentTotalAmount || item.CurrentTotalRealAmount || '0') : '--' }}
              </td>
            </template>
            <template #RenewTotalRealAmount="{item}">
              <td>
                {{ item.IsRenew && parseInt(item.ID) !== 0 ? '$' + (item.RenewTotalRealAmount || '0'): '--' }}
              </td>
            </template>
            <template #GoldDiscount="{item}">
              <td>
                {{ item.GoldDiscount }}%
              </td>
            </template>
            <template #ShopDiscount="{item}">
              <td>
                {{ item.ShopDiscount }}%
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <!--                <CButton color="info" size="sm" class="mr-1">-->
                <!--                  <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/>-->
                <!--                </CButton>-->
                <CButton color="danger" type="button" size="sm" @click="RemoveLevelChangeRecord(item)">
                  <CIcon name="cil-trash" class="c-icon-custom-size" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CTab>
        <CTab :title="$t('Order.ActivityLogEvent.MemberCart')">
          <div class="mb-3">
            <CButton @click="ChooseUsers = [User.uid];MessageType = 'Uncheckout';BulkSendEmail()" size="sm" color="success">通知顧客結帳</CButton>
          </div>
          <CDataTable
            id="DiscountTicket"
            addTableClasses="small text-nowrap"
            :items="MemberCart"
            :fields="MemberCartField"
            :loading="Loading"
            :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
            responsive
            hover
            striped
          >
            <template #ProdName="{item}">
              <td>
                {{item.Name}}
              </td>
            </template>
            <template #ProdNo="{item}">
              <td>
                {{item.SeriesNum}}
              </td>
            </template>
            <template #ProdPrice="{item}">
              <td>
                <p>
                  <span :class="(item.Discount < 1 ? 'text-danger p-text-line-through' : '')">
                    {{ $store.state.currency + '$' }} {{ item.Amount }}
                  </span><br>
                  <span v-show="(item.Discount < 1 && $store.getters.LoginStatus)" class="text-danger p-text-bold">
                    {{ $store.state.currency + '$' }} {{ Math.round(item.Amount * item.Discount) }}
                  </span>
                </p>
              </td>
            </template>
            <template #ProdQuantity="{item}">
              <td>
                {{ item.Quantity }}
              </td>
            </template>
            <template #ProdSum="{item}">
              <td>
                <p>
                  <span :class="(item.Discount < 1 ? 'text-danger' : 'text-danger')">
                    {{ $store.state.currency + '$'}}
                    {{ Math.round(item.Amount * item.Discount) * item.Quantity }}
                  </span>
                </p>
              </td>
            </template>
          </CDataTable>
        </CTab>
        <CTab :title="$t('Offer.Taxonomy.DiscountTicket')">
          <CDataTable
            id="DiscountTicket"
            addTableClasses="small text-nowrap"
            :items="DiscountTicket"
            :fields="DiscountTicketField"
            :loading="Loading"
            :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
            responsive
            hover
            striped
          >
            <template #TicketName="{item}">
              <td>
                <a :href="`/offer/discountTicket/detail/${item._id}`" target="_blank">{{item.Name}}</a>
              </td>
            </template>
            <template #TicketSlug="{item}">
              <td>
                <span>{{ item.Slug }}</span>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="DiscountTicketUsedStatusOptions[item.Status]">
                  {{ $t('Organization/Member.DiscountTicketUsedStatusOptions.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #TimeRange="{item}">
              <td>
                {{ $t('Offer.StartTime') }}: {{ $dayjs(item.StartTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}<br>
                {{ $t('Offer.EndTime') }}: {{ $dayjs(item.EndTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #SendTime="{item}">
              <td v-if="item.CreateTime">
                {{ $dayjs(item.CreateTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
          </CDataTable>
        </CTab>
        <CTab :title="$t('Organization/Member.ProviderLinking')">
          <template v-if="UserData.SocialProviders && UserData.SocialProviders.length > 0">
            <CCard v-for="(Item, Index) in UserData.SocialProviders" :key="Index">
              <CCardBody class="d-flex justify-content-between">
                <div>
                  <h4 class="text-success font-weight-bold">
                    {{ Item.Provider }}
                  </h4>
                  <div class="mt-4">
                    <CMedia :asideImageProps="{src: Item.Cover, class: 'rounded-circle'}">
                      <h5 class="mt-0">{{ Item.Name }}</h5>
                      <p class="mb-0">
                        {{ $t('Organization/Member.SocialProviderID') }}: {{ Item.Uid }}<br>
                        {{ $t('Organization/Member.StatusMessage') }}: {{ Item.StatusMessage || '沒有狀態' }}
                      </p>
                    </CMedia>
                  </div>
                </div>
              </CCardBody>
              <CElementCover
                v-if="(Submit === true)"
                :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
                :opacity="0.8"
              >
                <CSpinner size="sm" color="primary"/>
              </CElementCover>
            </CCard>
          </template>
          <CAlert v-else color="secondary" :fade="false" class="p-5 d-flex justify-content-center align-items-center flex-column">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style="width: 64px;height: 64px">
              <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
            </svg>
            <h4 class="mt-3">沒有資料</h4>
            <p class="mb-0">目前沒有連動資料，若已填入社群OpenID，請於隔天再查看連動結果。</p>
          </CAlert>
        </CTab>
      </CTabs>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true) || (Loading === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#CreateSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="CreateUserModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="CreateSubmit" @click="UserAction(Action)" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell"/>
        {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Uid'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Uid') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Uid"
            v-model="SearchFilter.Uid"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.Uid"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
        <template v-if="ShowFilter !== 'All' && ShowFilter === 'Uid'">
          <CCol tag="label" sm="3" class="col-form-label"></CCol>
          <CCol sm="9">
            <CTextarea class="mt-3" v-model="BulkUids" placeholder="輸入後換行或是逗號分隔可直接套入欄位內。" rows="6" @change="AutoInputUid" />
          </CCol>
        </template>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Email'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Email') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Email"
            v-model="SearchFilter.Email"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.Email"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
        <template v-if="ShowFilter !== 'All' && ShowFilter === 'Email'">
          <CCol tag="label" sm="3" class="col-form-label"></CCol>
          <CCol sm="9">
            <CTextarea class="mt-3" v-model="BulkEmails" placeholder="輸入後換行或是逗號分隔可直接套入欄位內，ex: service@ksong.com.tw,business@ksong.com.tw,devops@ksong.com.tw,sales@ksong.com.tw" rows="6" @change="AutoInputEmail" />
          </CCol>
        </template>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Class'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Class') }}
        </CCol>
        <CCol sm="9">
          <CSelect :options="ClassOptions" v-model="SearchFilter.Class" :value.sync="SearchFilter.Class" :placeholder="$t('Global.PleaseSelect')"/>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Country'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Country') }}
        </CCol>
        <CCol sm="9">
          <CSelect :options="CountryOptions" v-model="SearchFilter.Country" :value.sync="SearchFilter.Country" :placeholder="$t('Global.PleaseSelect')"/>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" v-model="SearchFilter.Name" :label="$t('Organization.Name')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Mobile'" v-model="SearchFilter.Mobile" :label="$t('Organization.Mobile')" type="text" horizontal />
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Tags'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.Tags') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Tags"
            v-model="SearchFilter.Tags"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="Tags"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
          <CInputRadioGroup class="mt-3" v-model="SearchFilterCondition.Tags" :options="ConditionOptions" :checked.sync="SearchFilterCondition.Tags" :custom="true" inline/>
        </CCol>
      </CRow>
      <CRow v-if="(ShowFilter === 'All' || ShowFilter === 'Level') && Features.includes('MemberLevel')" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.Level') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Level"
            v-model="SearchFilter.Level"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            label="Name"
            track-by="ID"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="MemberLevelList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
          <CInputRadioGroup class="mt-3" v-model="SearchFilterCondition['Level.ID']" :options="ConditionOptions" :checked.sync="SearchFilterCondition['Level.ID']" :custom="true" inline/>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'BirthdayMonth'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.BirthdayMonth') }}
        </CCol>
        <CCol sm="9">
          <CSelect :options="BirthDayMonthOptions" v-model="SearchFilter.BirthdayMonth" :value.sync="SearchFilter.BirthdayMonth" :placeholder="$t('Global.PleaseSelect')"/>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'TotalRealAmount'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.TotalRealAmount') }}
        </CCol>
        <CCol sm="9">
          <CInput placeholder="請輸入消費金額最小值" v-model="SearchFilter.TotalRealAmountMin">
            <template #prepend-content>
              $
            </template>
          </CInput>
          <CInput placeholder="請輸入消費金額最大值" v-model="SearchFilter.TotalRealAmountMax">
            <template #prepend-content>
              $
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'WalletBonus'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.WalletBonus') }}
        </CCol>
        <CCol sm="9">
          <CInput placeholder="請輸入購物金額度最小值" v-model="SearchFilter.WalletBonusMin">
            <template #prepend-content>
              $
            </template>
          </CInput>
          <CInput placeholder="請輸入購物金額度最大值" v-model="SearchFilter.WalletBonusMax">
            <template #prepend-content>
              $
            </template>
          </CInput>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'CreateTime'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization.CreateTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.TimeRange" is-range @input="SearchFilter.StartTime = SearchFilter.TimeRange.start;SearchFilter.EndTime = SearchFilter.TimeRange.end">
            <template v-slot="{ inputValue, inputEvents }">
              <CRow>
                <CCol col="12">
                  <CInput :placeholder="$t('Logistics.DeliverySettings.SelectStartDate')" :value="inputValue.start" v-on="inputEvents.start">
                    <template #append-content>
                      <CIcon name="cil-calendar"/>
                    </template>
                  </CInput>
                </CCol>
                <CCol col="12">
                  <CInput :placeholder="$t('Logistics.DeliverySettings.SelectEndDate')" :value="inputValue.end" v-on="inputEvents.end">
                    <template #append-content>
                      <CIcon name="cil-calendar"/>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
            </template>
          </v-date-picker>
          <CButton @click="SearchAllRange()" block color="dark">
            搜尋全部區間
          </CButton>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'City'" v-model="SearchFilter.City" :label="$t('Organization.City')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Area'" v-model="SearchFilter.Area" :label="$t('Organization.Area')" type="text" horizontal />
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Address'" v-model="SearchFilter.Address" :label="$t('Organization.Address')" type="text" horizontal />
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'PerPage'" horizontal :label="$t('Order.Filter.PerPage')" :options="PerPageOption" v-model="Pagination.PerPage" :value.sync="Pagination.PerPage" :placeholder="$t('Global.PleaseSelect')"/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.Sort') }}
        </CCol>
        <CCol sm="9">
          <CInputRadioGroup v-model="Order" :options="SortOption" :checked.sync="Order" :custom="true" inline/>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="FilterUser()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="BonusRecordModel" class="ActionModel" :show.sync="BonusRecordModel" size="xl" :centered="true" :closeOnBackdrop="!Submit">
      <CDataTable
        v-if="BonusRecordList.length > 0"
        id="BonusRecordList"
        addTableClasses="small text-nowrap"
        :items="BonusRecordList"
        :fields="BonusRecordField"
        :loading="Loading"
        responsive
        hover
        striped
      >
        <template #CreateTime="{item}">
          <td>
            {{ $dayjs(item.CreateTime._seconds * 1000).format('YYYY-MM-DD') }}
          </td>
        </template>
        <template #ExpiredTime="{item}">
          <td>
            {{ item.ExpiredTime && $dayjs(item.ExpiredTime._seconds * 1000).format('YYYY-MM-DD') || '--' }}
          </td>
        </template>
        <template #Operator="{item}">
          <td>
            {{ $t('Organization/Member.OperatorOptions.' + item.Operator) }}
          </td>
        </template>
        <template #Action="{item}">
          <td>
            {{ $t('Organization/Member.ActionOptions.' + item.Action) }}
          </td>
        </template>
        <template #Note="{item}">
          <td>
            {{ item.Note || '--' }}
          </td>
        </template>
      </CDataTable>
      <CAlert v-else color="info">
        <CIcon name="cil-bell" class="mr-1" />{{ $t('Organization/Member.NoBonusRecord') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Organization/Member.BonusRecord') }}: {{ UserData.Email }}</h5>
          <CButtonClose @click="BonusRecordModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <footer class="d-none">
        </footer>
      </template>
    </CModal>
    <CModal id="DistributeBonusModal" class="ActionModel" :show.sync="DistributeBonusModal" :centered="true" :closeOnBackdrop="!Submit">
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Organization/Member.BonusActionType') }}
        </CCol>
        <CCol sm="9">
          <CSelect class="mb-0" :options="[{value: 'positive', label: '增加額度'}, {value: 'negative', label: '扣除額度'}]" v-model="AddBonusData.BonusActionType" :value.sync="AddBonusData.BonusActionType" :placeholder="$t('Global.PleaseSelect')"/>
        </CCol>
      </CRow>
      <CInput horizontal v-model="AddBonusData.UseValue" :label="$t('Organization/Member.BonusValue')" type="number" />
      <CTextarea horizontal v-model="AddBonusData.Note" :label="$t('Organization/Member.BonusNote')" rows="3" />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Organization/Member.DistributeBonus') }}: {{ UserData.Email }}</h5>
          <CButtonClose @click="DistributeBonusModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true) || (Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DistributeBonusSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="DistributeBonusModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DistributeBonusSubmit" @click="DistributeBonus()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal class="ActionModel" :show.sync="AddTagModal" :centered="true" :closeOnBackdrop="!Submit">
      <multiselect
        v-model="CurrentTag"
        :tag-placeholder="$t('Global.EnterToAdd')"
        :placeholder="$t('Global.PleaseKeyIn')"
        :deselectLabel="$t('Global.DeselectLabel')"
        :selectedLabel="$t('Global.SelectedLabel')"
        :options="Tags"
        :multiple="true"
        :taggable="true"
        @tag="(prop) => CurrentTag.push(prop)">
        <template #noOptions>
          {{ $t('Global.NoOptions') }}
        </template>
      </multiselect>
      <template #header>
        <h5 class="modal-title">{{ $t('Organization/Member.EditTags') }}</h5>
        <CButtonClose @click="AddTagModal = false"/>
      </template>
      <template #footer>
        <CButton color="secondary" @click="AddTagModal = false">{{ $t('Global.Cancel') }}</CButton>
        <CButton color="success" @click="EditTag('addTags')">{{ $t('Organization/Member.AddTags') }}</CButton>
        <CButton color="danger" @click="EditTag('removeTags')">{{ $t('Organization/Member.RemoveTags') }}</CButton>
      </template>
    </CModal>
    <component
      :is="Component"
      :Loading="Loading"
      :Submit="Submit"
      :Toggle.sync="ToggleModal"
      :MemberList.sync="List"
      :ChooseUsers.sync="ChooseUsers"
      :MessageType.sync="MessageType"
      :Tags="Tags"
      @ImportSuccess="GetList()"
      @ExecuteExport="ExecuteExport"
    />
  </main>
</template>

<route>
{
"meta": {
"label": "會員列表"
}
}
</route>

<script>
import District from '@/assets/json/Districts.json'

export default {
  name: 'ListMember',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      List: [],
      MemberLevelList: [],
      User: {},
      BonusRecordList: [],
      AddBonusData: {},
      UserData: {
        OpenID: {},
        Level: {},
        Tags: [],
        Groups: [],
        Variable: {}
      },
      Tags: [],
      SearchFilterCondition: {
        Tags: 'Default',
        Class: 'Default',
        Country: 'Default',
        'Level.ID': 'Default',
      },
      LevelChangeLog: [],
      SearchFilter: {
        Uid: [],
        Email: [],
        Class: '',
        Name: '',
        Country: '',
        Mobile: '',
        Tags: [],
        Level: [],
        TotalRealAmountMin: '',
        TotalRealAmountMax: '',
        WalletBonusMin: '',
        WalletBonusMax: '',
        BirthdayMonth: '',
        StartTime: this.$dayjs().subtract(6, 'month').startOf('month').unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        TimeRange: {
          start: this.$dayjs().subtract(6, 'month').startOf('month').unix() * 1000,
          end: this.$dayjs().unix() * 1000
        },
        City: '',
        Area: '',
        Address: ''
      },
      StatusClassOption: {
        'P': 'dark',
        'R': 'light',
        'RF': 'light',
        'U': 'warning',
        'C': 'secondary',
        'F': 'danger',
        'N': 'danger',
        'X': 'danger',
        'Y': 'success',
        'H': 'info',
        'S': 'primary',
        'O': 'info',
        'A': 'primary',
      },
      DiscountTicketUsedStatusOptions: {
        Used: 'info',
        Unused: 'warning',
        Expired: 'danger'
      },
      District,
      Features: [],
      SystemBasicFeatures: [],
      Permission: [],
      NotifyFeatures: [],
      FilterModel: false,
      ShowFilter: 'All',
      PasswordVisible: false,
      Action: 'Update',
      CreateUserModel: false,
      AlertModel: false,
      BonusRecordModel: false,
      DistributeBonusModal: false,
      ChooseUsers: [],
      CheckAllValue: false,
      NextPageToken: null,
      Loading: false,
      Submit: false,
      MemberGroup: [],
      OrderHistory: [],
      DiscountTicket:[],
      MemberCart:[],
      noItemsView: {
        noResults: this.$t('Organization/Member.NoResults'),
        noItems: this.$t('Organization/Member.NoItems')
      },
      Order: 'nto',
      Component: null,
      ToggleModal: false,
      Pagination: {
        Page: 1,
        PerPage: 20,
        TotalItems: 0,
        TotalPages: 0
      },
      OrderPagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      BulkEmails: '',
      BulkUids: '',
      AddTagModal: false,
      CurrentTag: [],
      RemoveTag: [],
      MessageType: null,
      NewUI: false,
      TotalStatistics: {}
    }
  },
  computed: {
    SexOptions() {
      return [
        { value: 'M', label: this.$t('Organization.SexOptions.M') },
        { value: 'F', label: this.$t('Organization.SexOptions.F') },
      ]
    },
    ConditionOptions() {
      return Object.keys(this.$t('Global.ConditionOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Global.ConditionOptions')[option]
        }
      })
    },
    ClassOptions() {
      return Object.keys(this.$t('Organization.ClassOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Organization.ClassOptions')[option]
        }
      })
    },
    MemberLevelListOptions() {
      return this.MemberLevelList.map(level => {
        return {
          value: level.ID,
          label: level.Name
        }
      })
    },
    LevelChangeLogOptions() {
      return this.LevelChangeLog.map(log => {
        return {
          value: log.LevelChangeID,
          label: `${this.$dayjs(log.CreateTime._seconds * 1000).format('YYYY-MM-DD HH:mm:ss')} (${log.Name})`
        }
      })
    },
    CountryOptions () {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(option => {
        return {
          value: option,
          label: this.$t('Logistics.CountryOptions')[option]
        }
      })
    },
    PerPageOption() {
      return Object.keys(this.$t('Organization/Member.PerPageOption')).map(option => {
        return {
          value: parseInt(option),
          label: this.$t('Organization/Member.PerPageOption')[option]
        }
      })
    },
    SortOption() {
      return Object.keys(this.$t('Organization/Member.SortType')).map(option => {
        return {
          value: option,
          label: this.$t('Organization/Member.SortType')[option]
        }
      })
    },
    City () {
      return this.District.map(Data => Data.name)
    },
    Area () {
      const ChooseCityData = this.District.filter(Data => Data.name === this.UserData.City) || []
      if (ChooseCityData.length > 0) {
        return ChooseCityData[0].districts.map(Area => Area.name)
      } else {
        return []
      }
    },
    Field() {
      const DefaultField = [
        { key: 'Checkbox', label: '' },
        { key: 'Action', label: '' },
        { key: 'Name', label: this.$t('Organization.Name') },
        { key: 'Contact', label: this.$t('Organization.Contact') },
        { key: 'creationTime', label: this.$t('Organization.ChangeTime') },
        { key: 'Class', label: this.$t('Organization.Class') },
        // { key: 'lastSignInTime', label: this.$t('Organization.LastLoginTime') }
      ]
      if (this.Features.includes('MemberLevel')) {
        DefaultField.push({
          key: 'Level',
          label: this.$t('Organization/Member.Level')
        })
      }
      if (this.Features.includes('Bonus')) {
        DefaultField.push({
          key: 'Bonus',
          label: this.$t('Organization/Member.Bonus')
        })
      }
      DefaultField.push(
        { key: 'disabled', label: this.$t('Organization.Status') },
        { key: 'emailVerified', label: this.$t('Organization.EmailVerified') }
      )
      return DefaultField
    },
    BonusRecordField() {
      return [
        { key: 'CreateTime', label: '發送時間' },
        { key: 'CurrentValue', label: '交易前額度' },
        { key: 'UseValue', label: '額度變化' },
        { key: 'ResultValue', label: '交易後額度' },
        { key: 'Operator', label: '操作身份' },
        { key: 'Action', label: '來源/操作動作' },
        { key: 'ActionValue', label: '來源名稱/操作者名稱' },
        { key: 'Note', label: '備註' }
      ]
    },
    MemberCartField(){
      return [
        { key: 'ProdName', label: '商品名稱' },
        { key: 'ProdNo', label: '商品編號' },
        { key: 'ProdPrice', label: '商品價格' },
        { key: 'ProdQuantity', label: '商品數量' },
        { key: 'ProdSum', label: '商品小計' }
      ]
    },
    LevelChangeLogField() {
      return [
        { key: 'CreateTime', label: '變更時間' },
        { key: 'Name', label: '等級名稱' },
        { key: 'FullAmount', label: '升級條件' },
        { key: 'RenewAmount', label: '續期條件' },
        { key: 'GoldDiscount', label: '當前獲得購物金比率' },
        { key: 'ShopDiscount', label: '當前獲得折扣' },
        { key: 'CurrentTotalAmount', label: '當前升等消費金額' },
        { key: 'RenewTotalRealAmount', label: '當前續期消費金額' },
        { key: 'Action', label: '' },
      ]
    },
    OrderHistoryField() {
      return [
        { key: 'OrderNum', label: '訂單編號' },
        { key: 'Origin', label: '結帳來源頁面' },
        { key: 'TotalAmount', label: this.$t('Organization/Member.TotalOrderAmount') },
        { key: 'RealAmount', label: this.$t('Organization/Member.TotalRealAmount') },
        { key: 'PaymentStatus', label: '付款狀態' },
        { key: 'LogisticsStatus', label: '配送狀態' },
        { key: 'OrderStatus', label: '訂單狀態' },
        { key: 'CreateTime', label: '建立時間' },
      ]
    },
    DiscountTicketField(){
      return [
        { key: 'TicketName', label: '折價券名稱' },
        { key: 'TicketSlug', label: '折價券代稱' },
        { key: 'Status', label: '使用狀態' },
        // { key: 'TimeRange', label: '可使用區間' },
        { key: 'SendTime', label: '發送時間' }
      ]
    },
    BirthDayMonthOptions() {
      const options = []
      for (let i = 1; i <= 12; i++){
        options.push(i.toString().padStart(2, '0'))
      }
      return options
    }
  },
  created() {
    const PermissionSession = JSON.parse(localStorage.getItem('Permission'))
    this.NewUI = PermissionSession.Navigation.Dashboard && PermissionSession.Navigation.Dashboard.includes('UMEANS_ECRM') || false
    this.Features = PermissionSession.Features.Organization ? (PermissionSession.Features.Organization.Member || []) : []
    this.NotifyFeatures = PermissionSession.Features.Notify || []
    this.SystemBasicFeatures = PermissionSession.Features.System.Basic
    this.Permission = PermissionSession.Permission.Organization.Member || []
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      const Executes = [
        this.GetAllTags(),
        this.GetList()
      ]
      if (this.Features.includes('MemberLevel')) {
        Executes.push(
          this.GetMemberLevelList()
        )
      }
      if (this.SystemBasicFeatures.includes('B2BIdentity')) {
        setTimeout(() => {
          Executes.push(
            this.GetExtendClassOptions()
          )
        }, 1000)
      }
      return Promise.all(Executes).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    OpenCreateUserModel() {
      this.Action = 'Create'
      this.CreateUserModel = true
      this.User = {}
      this.UserData = {
        Tags: [],
        Groups: []
      }
    },
    GetList() {
      this.ChooseUsers = []
      this.FilterModel = false
      this.Loading = true
      const data = this.HandleFilter({})
      if (parseInt(this.SearchFilter.TotalRealAmountMin) > parseInt(this.SearchFilter.TotalRealAmountMax)) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': 消費金額最小值不可大於最大值',
          type: 'error'
        })
        return false
      }
      if (parseInt(this.SearchFilter.WalletBonusMin) > parseInt(this.SearchFilter.WalletBonusMax)) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': 購物金額度最小值不可大於最大值',
          type: 'error'
        })
        return false
      }
      data.SearchFilterCondition = this.SearchFilterCondition
      return this.$store.dispatch('InnerRequest', {
        url: '/user/list',
        method: 'post',
        data
      }).then(({ data }) => {
        this.TotalStatistics = data.totalStatistics || {}
        this.Pagination = data.pagination
        this.List = data.list.map(item => {
          item.Country = item.Country === 'TW-MAIN' || item.Country === 'TW-OUTLYING' ? 'TW' : item.Country
          return item
        })
        this.Loading = false
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetExtendClassOptions() {
      return this.$store.state.userProjectApp.database().ref(`/B2BIdentity`).once('value').then((snapshot) => {
        const Data = snapshot.val() || []
        const i18nMessage = this.$i18n.messages[this.$i18n.locale]
        Data.forEach((item => {
          i18nMessage.Organization.ClassOptions[item.ID] = item.Name
        }))
        this.$i18n.setLocaleMessage(this.$i18n.locale, i18nMessage)
      })
    },
    FilterUser () {
      this.Pagination.Page = 1
      this.GetList()
    },
    UserAction(type = 'Update') {
      switch (type) {
        case 'Update':
          this.Update()
          break
        case 'Create':
          this.Create()
          break
      }
    },
    GetUser(userId) {
      this.Loading = true
      this.Action = 'Update'
      this.CreateUserModel = true
      const UserAPI = this.$store.dispatch('InnerRequest', {
        url: '/user/get',
        method: 'post',
        data: {
          userId
        }
      }).then(({data}) => {
        this.Loading = false
        this.User = data.user
        this.UserData = {
          ...data.userData,
          FacebookOpenID: data.userData.OpenID.Facebook,
          LineOpenID: data.userData.OpenID.Line,
          Phone: data.userData.Phone ? data.userData.Phone.replace(this.$t('Global.CountryCallPrefixOptions.' + (data.userData.Country || 'TW')), '') : '',
          Mobile: data.userData.Mobile ? data.userData.Mobile.replace(this.$t('Global.CountryCallPrefixOptions.' + (data.userData.Country || 'TW')), '') : ''
        }
        this.DiscountTicket = data.discountTicket
        this.LevelChangeLog = data.levelChangeLog
        this.$router.replace({path: '/organization/member/list', query: {editUser: 'true'}})
      }).catch((err) => {
        throw err
      })
      const OrderAPI =  this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/list2',
          Data: {
            UserID: userId,
            Taxonomy: 'ProductOrder',
            Page: this.OrderPagination.Page,
            PerPage: this.OrderPagination.PerPage,
            Order: 'nto',
            SearchAllTimeRange: true
          }
        }
      }).then(({data}) => {
        this.OrderHistory = data.Data.map(item => {
          item.OriginType = item.Variable.CartType ? item.Variable.CartType.split('-')[0] : 'Default'
          item.OriginPageSlug = item.Variable.CartType !== 'Default' ? (item.Variable.CartType || '').replace('SinglePage-', '') : '--'
          item.OriginPage = item.Variable.CartTypeName || (item.Variable.CartType || '').replace('SinglePage-', '')
          return item
        })
        this.OrderPagination = {
          Page: data.Pagination.Page,
          PerPage: data.Pagination.PerPage.toString(),
          TotalItems: data.Pagination.TotalItems,
          TotalPages: data.Pagination.TotalPages
        }
      }).catch((err) => {
        throw err
      })

      const CartAPI = this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/cart/get',
          Data: {
            UserID: userId || '0',
            VisitID: '0',
            CartType: 'Default'
          }
        }
      }).then(({data}) => {
        this.MemberCart = data.Data.map(item => {
          return{
            ...item,
            Amount: item.ProductInfo.Amount.Actual,
            Name: item.ProductInfo.Name,
            Cover: item.ProductInfo.Cover,
            SeriesNum: item.ProductInfo.SeriesNum
          }
        })
      }).catch((err) => {
        console.log(err)
        throw err
      })

      return Promise.all([
          UserAPI, OrderAPI, CartAPI
      ]).then(() => {
        this.Loading = false
        return true
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.CreateUserModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': ' + err.msg.message || err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    Create() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/user/create',
        method: 'post',
        data: {
          ...this.User,
          userData: {
            ...this.UserData,
            Phone: this.$t('Global.CountryCallPrefixOptions.' + (this.UserData.Country || 'TW')) + this.UserData.Phone,
            Mobile: this.$t('Global.CountryCallPrefixOptions.' + (this.UserData.Country || 'TW')) + this.UserData.Mobile
          },
          claims: {
            class: this.UserData.Class || 'M',
            groups: this.UserData.Groups,
            tags: this.UserData.Tags,
            family: this.UserData.Family,
            level: {
              ID: 0,
              Name: '一般會員'
            }
          }
        }
      }).then(() => {
        this.GetList()
        this.ResetState(true)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/AddSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        console.log(err)
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Organization/AddFail') + ': ' + err.msg,
          type: 'error'
        })
      })
    },
    Update() {
      this.Submit = true
      const UpdateData = {
        ...this.User,
        userData: this.UserData,
        userId: this.User.uid,
        // claims: {
        //   class: this.UserData.Class,
        //   groups: this.UserData.Groups,
        //   tags: this.UserData.Tags,
        //   family: this.UserData.Family,
        //   level: {
        //     ID: this.UserData.Level ? this.UserData.Level.ID : 0,
        //     Name: this.UserData.Level ? this.UserData.Level.Name : '一般會員'
        //   }
        // }
      }
      if (this.UserData.Phone) {
        UpdateData.userData.Phone = this.$t('Global.CountryCallPrefixOptions.' + (this.UserData.Country || 'TW')) + this.UserData.Phone
      }
      if (this.UserData.Mobile) {
        UpdateData.userData.Mobile = this.$t('Global.CountryCallPrefixOptions.' + (this.UserData.Country || 'TW')) + this.UserData.Mobile
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/user/update',
        method: 'post',
        data: UpdateData
      }).then(() => {
        this.GetList()
        this.ResetState(true)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/EditSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        console.log(err)
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Organization/EditFail') + ': ' + err.msg,
          type: 'error'
        })
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/user/delete',
        method: 'post',
        data: {
          userId: this.ChooseUsers
        }
      }).then(() => {
        this.GetList()
        this.ResetState(true)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.ResetState()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Organization/DeleteFail') + ': ' + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseUsers = this.List.map(data => {
          return data.Uid
        })
      } else {
        this.CheckAllValue = false
        this.ChooseUsers = []
      }
    },
    ResetState(closeCreateUserModel = false) {
      if (closeCreateUserModel) {
        this.CreateUserModel = false
      }
      this.Submit = false
      this.AlertModel = false
      this.ChooseUsers = []
    },
    SetZip () {
      this.UserData.ZipCode = parseInt(this.District.filter(Data => Data.name === this.UserData.City)[0].districts.filter(Area => Area.name === this.UserData.Area)[0].zip)
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.Pagination = {
        Page: 1,
        PerPage: 30,
        TotalItems: 0,
        TotalPages: 0
      }
      this.SearchFilter = {
        Uid: [],
        Email: [],
        Class: '',
        Name: '',
        Country: '',
        Mobile: '',
        Tags: [],
        TotalRealAmountMin: '',
        TotalRealAmountMax: '',
        WalletBonusMin: '',
        WalletBonusMax: '',
        BirthdayMonth: '',
        Level: [],
        StartTime: this.$dayjs().subtract(6, 'month').startOf('month').unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        TimeRange: {
          start: this.$dayjs().subtract(6, 'month').startOf('month').unix() * 1000,
          end: this.$dayjs().unix() * 1000
        },
        City: '',
        Area: '',
        Address: ''
      }
      this.ChooseIndex = []
      this.Init()
    },
    GetUserBonusRecord (user) {
      this.UserData = user
      this.Loading = true
      this.BonusRecordModel = true
      return this.$store.dispatch('InnerRequest', {
        url: '/user/wallet/record/Bonus',
        method: 'get',
        params: {
          userId: user.Uid
        }
      }).then(({data}) => {
        this.Loading = false
        this.BonusRecordList = data.record
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.BonusRecordModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': ' + err.msg.message,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    OpenDistributeBonusModal (user) {
      this.UserData = user
      this.DistributeBonusModal = true
      this.AddBonusData.BonusActionType = 'positive'
    },
    CheckPhone (type = 'Phone') {
      switch (this.UserData.Country) {
        case 'TW':
          this.UserData[type] = this.UserData[type].replace(/^09/, '9')
          break
      }
    },
    DistributeBonus () {
      this.Submit = true
      switch (this.AddBonusData.BonusActionType) {
        case 'positive':
        default:
          this.AddBonusData.UseValue *= 1
          break
        case 'negative':
          this.AddBonusData.UseValue *= -1
          console.log(this.AddBonusData.UseValue)
          break
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/user/wallet/add/Bonus',
        method: 'post',
        data: {
          userId: this.UserData.Uid,
          UseValue: this.AddBonusData.UseValue,
          Note: this.AddBonusData.Note
        }
      }).then(() => {
        this.UserData = {}
        this.AddBonusData = {}
        this.DistributeBonusModal = false
        this.Submit = false
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/UpdateUserBonusSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        console.log(err)
        this.UserData = {}
        this.AddBonusData = {}
        this.DistributeBonusModal = false
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Organization/UpdateUserBonusFail') + ': ' + err.msg.message,
          type: 'error'
        })
      })
    },
    BulkSendEmail () {
      this.Component = () => import('@/components/member/SendEmail')
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    BulkSendSMSMessage () {
      this.Component = () => import('@/components/member/SendSMS')
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    BulkImportMember (type = 'GoogleSheets') {
      switch (type) {
        case 'GoogleSheets':
          this.Component = () => import('@/components/member/BulkImportMember/GoogleSheets')
          break
        default:
          return false
      }
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    IssueTicket () {
      this.Component = () => import('@/components/member/IssueTicket')
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    GetMemberLevelList () {
      this.Loading = true
      this.MemberLevelList = [
        {
          ID: 0,
          Name: '一般會員',
          ShopDiscount: 100,
          GoldDiscount: 0,
          FullAmount: 0,
          NextFullAmount: '--',
          Status: true
        }
      ]
      return this.$store.dispatch('InnerRequest', {
        url: '/user/level/list',
        method: 'post'
      }).then(({data}) => {
        this.Loading = false
        Object.values(data).forEach((item) => {
          this.MemberLevelList.push(item)
        })
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        throw err
      })
    },
    ChangeOrderPage(){
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/list2',
          Data: {
            UserID: this.User.uid,
            Taxonomy: 'ProductOrder',
            Page: this.OrderPagination.Page,
            PerPage: this.OrderPagination.PerPage,
            Order: 'nto',
            SearchAllTimeRange: true
          }
        }
      }).then(({data}) => {
        this.OrderHistory = data.Data.map(item => {
          item.OriginType = item.Variable.CartType ? item.Variable.CartType.split('-')[0] : 'Default'
          item.OriginPageSlug = item.Variable.CartType !== 'Default' ? (item.Variable.CartType || '').replace('SinglePage-', '') : '--'
          item.OriginPage = item.Variable.CartTypeName || (item.Variable.CartType || '').replace('SinglePage-', '')
          return item
        })
        this.OrderPagination = {
          Page: data.Pagination.Page,
          PerPage: data.Pagination.PerPage.toString(),
          TotalItems: data.Pagination.TotalItems,
          TotalPages: data.Pagination.TotalPages
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetAllTags () {
      this.Submit = true
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: '/member/tags',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
        },
        headers: {
          'id-token': localStorage.getItem('XX-CSRF-Token')
        }
      }).then(({ data }) => {
        this.Loading = false
        this.Submit = false
        this.Tags = data.Data
      }).catch((error) => {
        this.Loading = false
        this.Submit = false
        throw error
      })
    },
    EditTag(Action = 'addTags') {
      this.Submit = true
      this.$Progress.start()
      // removeTags
      return this.$store.dispatch('InnerRequest', {
        url: '/user/' + Action,
        method: 'post',
        data: {
          userIds: this.ChooseUsers,
          currentTags: this.CurrentTag
        }
      }).then(() => {
        this.CurrentTag = []
        this.Submit = false
        this.AddTagModal = false
        this.$Progress.finish()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Organization/EditSuccess'),
          type: 'success'
        })
        this.GetList()
      }).catch((error) => {
        this.$Progress.fail()
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/UpdateFail') + ': ' + error.msg.message || error.msg || 'Unknown',
          type: 'error'
        })
        throw error
      })
    },
    Export(type = 'GoogleSheets'){
      switch (type) {
        case 'GoogleSheets':
          this.Component = () => import('@/components/ExportToGoogleSheet')
          break
        default:
          return
      }
      setTimeout(() => {
        this.ToggleModal = true
      }, 500)
    },
    ExecuteExport(value) {
      this.Loading = true
      this.$Progress.start()
      const data = this.HandleFilter({
        SheetURL: value.SheetURL
      })
      data.SearchFilterCondition = this.SearchFilterCondition
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/user/export/googleSheet',
        method: 'post',
        data
      }).then(() => {
        this.Loading = false
        this.ToggleModal = false
        this.$Progress.finish()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/ExportSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ExportFail'),
          type: 'error'
        })
      })
    },
    SearchAllRange() {
      this.SearchFilter.StartTime = ''
      this.SearchFilter.EndTime = ''
      this.SearchFilter.TimeRange = {
        start: null,
        end: null
      }
      this.GetList()
    },
    ExportCSV () {
      this.Loading = true
      this.$Progress.start()
      const data = this.HandleFilter({})
      data.SearchFilterCondition = this.SearchFilterCondition
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/user/export/csv',
        method: 'post',
        data
      }).then(({ data }) => {
        this.Loading = false
        this.ToggleModal = false
        this.$Progress.finish()

        const BinaryString = window.atob(data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `會員匯出-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ExportFail'),
          type: 'error'
        })
      })
    },
    HandleFilter (filter = {}) {
      const data = {
        Page: this.Pagination.Page,
        PerPage: this.Pagination.PerPage || 20,
        Order: this.Order || 'nto',
        ...filter
      }
      const FilterData = ['Uid', 'Class', 'StartTime', 'EndTime', 'Address', 'City', 'Area', 'WalletBonusMin', 'WalletBonusMax', 'TotalRealAmountMax', 'TotalRealAmountMin', 'Email', 'Country', 'Mobile', 'Name', 'Level', 'Tags', 'BirthdayMonth']
      FilterData.forEach((field) => {
        switch (typeof this.SearchFilter[field]) {
          case 'object':
            if (Array.isArray(this.SearchFilter[field]) && this.SearchFilter[field].length > 0) {
              switch (field) {
                case 'Level':
                  data['Level.ID'] = this.SearchFilter[field].map(item => item.ID)
                  break
                default:
                  data[field] = this.SearchFilter[field]
                  break
              }
            } else {
              switch (field) {
                case 'StartTime':
                  data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
              }
            }
            break
          default:
            if (this.SearchFilter[field]) {
              switch (field) {
                case 'StartTime':
                  data[field] = this.$dayjs(this.SearchFilter.StartTime).startOf('day').unix()
                  break
                case 'EndTime':
                  data[field] = this.$dayjs(this.SearchFilter.EndTime).endOf('day').unix()
                  break
                default:
                  data[field] = this.SearchFilter[field]
                  break
              }
            }
            break
        }
      })
      if (this.ChooseUsers.length > 0) {
        data.Uid = this.ChooseUsers
      }
      return data
    },
    AutoInputEmail () {
      this.SearchFilter.Email = this.BulkEmails.replace(/\n/g, ',').split(',')
      this.BulkEmails = ''
    },
    AutoInputUid () {
      this.SearchFilter.Uid = this.BulkUids.replace(/\n/g, ',').split(',')
      this.BulkUids = ''
    },
    SetLevelChangeRecord(item) {
      console.log(item)
    },
    RemoveLevelChangeRecord(item) {
      return this.$swal({
        icon: 'info',
        title: this.$t('Message.Info'),
        text: '是否要移除此紀錄？',
        showCancelButton: true,
        confirmButtonColor: '#2eb85c',
        confirmButtonText: this.$t('Global.Submit'),
        cancelButtonText: this.$t('Global.Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.Loading = true
          return this.$store.dispatch('InnerRequest', {
            url: '/user/removeLevelLog',
            method: 'post',
            data: {
              userId: this.User.uid,
              levelChangeLogID: item.LevelChangeID
            }
          }).then(() => {
            return this.$store.dispatch('InnerRequest', {
              url: '/user/get',
              method: 'post',
              data: {
                userId: this.User.uid
              }
            }).then(({data}) => {
              this.Loading = false
              this.User = data.user
              this.UserData = {
                ...data.userData,
                FacebookOpenID: data.userData.OpenID.Facebook,
                LineOpenID: data.userData.OpenID.Line,
                Phone: data.userData.Phone ? data.userData.Phone.replace(this.$t('Global.CountryCallPrefixOptions.' + (data.userData.Country || 'TW')), '') : '',
                Mobile: data.userData.Mobile ? data.userData.Mobile.replace(this.$t('Global.CountryCallPrefixOptions.' + (data.userData.Country || 'TW')), '') : ''
              }
              this.DiscountTicket = data.discountTicket
              this.LevelChangeLog = data.levelChangeLog
            })
          }).catch((error) => {
            console.log(error)
            this.Loading = false
            this.$Progress.fail()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Global/ErrorMessage') + error.msg,
              type: 'error'
            })
          })
        }
      })
    },
    RedirectToECRM() {
      this.$store.dispatch('InnerRequest', {
        url: '/user/createCustomToken',
        method: 'get',
      }).then(({ data }) => {
        return window.open(`https://ecrm.umeans.net/callback?token=${data.Data}&redirectPath=/member`)
      })
    }
  }
}
</script>

<style lang="scss">
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#MemberList  {
  thead {
    th {
      white-space: nowrap;
      &:first-child {
        text-align: center;
      }
    }
  }
  tbody {
    td {
      white-space: nowrap;
      &:first-child {
        vertical-align: middle;
      }
    }
  }
}
</style>
