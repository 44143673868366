<template>
  <main>
    <CAlert color="info" :fade="false">
      <i class="fas fa-info-circle mr-2" />{{ $t('Product/Gift.Description') }}
    </CAlert>
    <CCard>
      <CAlert id="ChooseNotice" v-if="(ChooseIndex.length > 0)" color="info" :fade="false">
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseIndex.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
        <div class="ButtonGroup">
          <CButton color="light" size="sm" class="mr-2" @click="ChooseIndex = [];CheckAllValue = false">
            {{ $t('Global.Cancel') }}
          </CButton>
          <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('D')" color="danger" size="sm"
                   @click="AlertModel = true">{{ $t('Global.Delete') }}
          </CButton>
        </div>
      </CAlert>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Products/Gift') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CDropdown addTogglerClasses="btn-outline-success btn-pill" placement="bottom-start" class="d-inline-block mr-2">
              <template #toggler-content>
                <CIcon size="sm" name="cil-library" class="mr-1" />{{ $store.getters.collection && $store.getters.collection.label || '預設資料集' }}
              </template>
              <CDropdownItem v-for="collection in $store.getters.collectionList" :key="collection._id" @click="$store.dispatch('ChangeCollection', collection)">
                {{ collection.label }}
              </CDropdownItem>
            </CDropdown>
            <CDropdown addTogglerClasses="btn-outline-danger btn-pill" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Product/List.Action') }}
              </template>
              <CDropdownHeader>
                {{ $t('Global.Export') }}
              </CDropdownHeader>
              <CDropdownItem @click="Export('CSV')">
                {{ $t('Global.ExportToCSV') }}
              </CDropdownItem>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader>
        <CRow>
          <CCol col="8">
            <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('SeriesNum')">
              {{ $t('Product/List.GiftSeriesNum') }}: {{ SearchFilter.SeriesNum.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('SKU')">
              {{ $t('Product/List.SKU') }}: {{ SearchFilter.SKU.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
              {{ $t('Product/List.GiftName') }}: {{ SearchFilter.Name || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Sort')">
              {{ $t('Product/Detail.Sort') }}: {{ $t('Product/List.SortType.' + this.Order) || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" @click="ResetFilter()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
          <CCol col="4" class="text-right">
            <CButton color="success" variant="outline" shape="pill" class="mr-2" @click="$router.push('/offerItems/gift/add')">
              <CIcon size="sm" class="mr-1" name="cil-plus"/> {{ $t('Global.Add') }}
            </CButton>
            <CButton color="success" variant="outline" shape="pill" @click="ImportFromCurrent">
              <CIcon size="sm" class="mr-1" name="cil-plus"/> {{ $t('Product/List.ImportFromCurrent') }}
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          id="ProductList"
          :items="List"
          :fields="Field"
          :loading="Loading"
          :noItemsView="noItemsView"
          responsive
          hover
        >
          <template #Checkbox-header>
            <label class="mb-0">
              <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
            </label>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-List-' + item.SeriesNum" :value="item.SeriesNum"
                       v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #Cover="{item}">
            <td>
              <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
            </td>
          </template>
          <template #Info="{item}">
            <td class="small">
              <div>
                <b>{{ $t('Product/List.SeriesNum') }}</b>: {{ item.SeriesNum }}
              </div>
              <div>
                <b>{{ $t('Product/List.CustomSeriesNum') }}</b>: {{ item.CustomSeriesNum || '--' }}
              </div>
              <div class="text-truncate" style="max-width: 300px;">
                <b>{{ $t('Product/List.Name') }}</b>: <a v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" class="text-info" href="javascript:void(0)" @click="$router.push(`/products/detail/${item.SeriesNum}`)">{{ item.Name }}</a><span v-else>{{ item.Name }}</span>
              </div>
            </td>
          </template>
          <template #Stock="{item}">
            <td class="small">
              <template v-if="item.EnableStock === 1">
                <p class="mb-0"><b>SKU</b>: {{ item.Stock.SKU }}</p>
                <p class="mb-0"><b>{{ $t('Product.RealStock') }}</b>: {{ item.Stock.RealStock }}</p>
                <p class="mb-0"><b>{{ $t('Product.SafeStock') }}</b>: {{ item.Stock.SafeStock }}</p>
              </template>
              <template v-else>
                <CBadge color="warning">
                  {{ $t('Product/List.UnableStock') }}
                </CBadge>
              </template>
            </td>
          </template>
          <template #Status="{item}">
            <td>
              <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                {{ $t('Product.Status.' + item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Action="{item}">
            <td>
              <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" color="info"
                       size="sm" class="mr-1" v-c-tooltip="$t('Product/List.Edit')"
                       @click="$router.push(`/offerItems/giftDetail/${item.SeriesNum}`)">
                <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/>
              </CButton>
              <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')"
                       class="btn btn-sm btn-secondary mr-1" v-c-tooltip="$t('Global.BlankTarget')"
                       @click="OpenWindow(`/offerItems/giftDetail/${item.SeriesNum}`)">
                <CIcon name="cil-clone" class="c-icon-custom-size mr-1"/>
              </CButton>
              <CButton class="btn btn-sm btn-secondary" v-c-tooltip="$t('Product/List.Duplicate')"
                       @click="Duplicate(item.SeriesNum)">
                <CIcon name="cil-copy" class="c-icon-custom-size mr-1"/>
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination :activePage.sync="Pagination.Page" :pages.sync="Pagination.TotalPages"
                     @update:activePage="ChangePage()"/>
      </CCardFooter>
    </CCard>
    <ProductList :Toggle.sync="ProductListModel" ChooseProductType="Default" @ChooseProduct="ChooseProduct" />
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell"/>
        {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" :label="$t('Product/List.GiftName')"
              v-model="SearchFilter.Name" horizontal/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SeriesNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.GiftSeriesNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SeriesNum"
            v-model="SearchFilter.SeriesNum"
            :optionHeight="24"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.SeriesNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SKU'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/List.SKU') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SKU"
            v-model="SearchFilter.SKU"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.SKU"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Sort') }}
        </CCol>
        <CCol sm="9">
          <CInputRadioGroup v-model="Order" :options="SortOption" :checked.sync="Order" :custom="true" inline/>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "贈品目錄"
}
}
</route>

<script>
export default {
  name: 'GiftList',
  layout: 'manage',
  components: {
    ProductList: () => import('@/components/product/ProductList'),
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data() {
    return {
      List: [],
      CategoryList: [],
      ShowFilter: 'All',
      SearchFilterType: null,
      SearchFilter: {
        SelectCategories: [],
        SelectCategoriesName: '',
        SeriesNum: [],
        SKU: [],
        CustomSeriesNum: [],
        Tags: [],
        Brand: [],
        Name: '',
      },
      AlertModel: false,
      BulkEditModel: false,
      FilterModel: false,
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'create_nto',
      Loading: false,
      Submit: false,
      ProductListModel: false,
      noItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      }
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Checkbox', label: ''},
        {key: 'Cover', label: this.$t('Product/List.Cover')},
        {key: 'Info', label: this.$t('Product/List.Info')},
        {key: 'Stock', label: this.$t('Product/List.Stock')},
        {key: 'Status', label: this.$t('Product/List.Status')},
        {key: 'Action', label: ''}
      ]
    },
    SortOption() {
      return [
        {value: 'otn', label: this.$t('Product/List.SortType.otn')},
        {value: 'nto', label: this.$t('Product/List.SortType.nto')},
        {value: 'lth', label: this.$t('Product/List.SortType.lth')},
        {value: 'htl', label: this.$t('Product/List.SortType.htl')},
        {value: 'sort_asc', label: this.$t('Product/List.SortType.sort_asc')},
        {value: 'sort_desc', label: this.$t('Product/List.SortType.sort_desc')},
        {value: 'customSeriesNum_asc', label: this.$t('Product/List.SortType.customSeriesNum_asc')},
        {value: 'customSeriesNum_desc', label: this.$t('Product/List.SortType.customSeriesNum_desc')},
        {value: 'seriesNum_asc', label: this.$t('Product/List.SortType.seriesNum_asc')},
        {value: 'seriesNum_desc', label: this.$t('Product/List.SortType.seriesNum_desc')}
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        this.Submit = false
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'page':
            case 'perPage':
            case 'order':
              break
            case 'category':
              this.SearchFilter.SelectCategories = this.CategoryList.filter(data => this.$route.query.category.split(',').includes(data.value))
              break
            case 'categoryName':
              this.SearchFilter.SelectCategoriesName = this.$route.query.categoryName
              break
            case 'name':
              this.SearchFilter.Name = this.$route.query.name
              break
            case 'customSeriesNum':
              this.SearchFilter.CustomSeriesNum = this.$route.query.customSeriesNum.split(',')
              break
            case 'seriesNum':
              this.SearchFilter.SeriesNum = this.$route.query.seriesNum.split(',')
              break
            case 'sku':
              this.SearchFilter.SKU = this.$route.query.sku.split(',')
              break
            case 'tags':
              this.SearchFilter.Tags = this.$route.query.tags.split(',')
              break
            case 'brand':
              this.SearchFilter.Brand = this.$route.query.brand.split(',')
              break
          }
        })
        return true
      }).catch((err) => {
        this.Submit = false
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order,
        taxonomy: 'Gift',
        site: this.$store.getters.collection.value
      }
      //todo 判斷確認 要調整
      if (this.$route.query.name) {
        RequestQuery.name = this.$route.query.name
      }
      if (this.$route.query.category) {
        RequestQuery.category = this.$route.query.category
      }
      if (this.$route.query.customSeriesNum) {
        RequestQuery.customSeriesNum = this.$route.query.customSeriesNum
      }
      if (this.$route.query.seriesNum) {
        RequestQuery.seriesNum = this.$route.query.seriesNum
      }
      if (this.$route.query.sku) {
        RequestQuery.sku = this.$route.query.sku
      }
      if (this.$route.query.tags) {
        RequestQuery.tags = this.$route.query.tags
      }
      if (this.$route.query.brand) {
        RequestQuery.brand = this.$route.query.brand
      }
      this.List = []
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/product/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
        taxonomy: 'Gift'
      }
      //todo 判斷確認 要調整
      if (this.SearchFilter.Name) {
        query.name = this.SearchFilter.Name
      }
      if (this.SearchFilter.SelectCategories.length > 0) {
        query.category = this.SearchFilter.SelectCategories.map(data => data.value).join(',')
        query.categoryName = this.SearchFilter.SelectCategories.map(data => data.label).join(',')
      }
      if (this.SearchFilter.CustomSeriesNum.length > 0) {
        query.customSeriesNum = this.SearchFilter.CustomSeriesNum.join(',')
      }
      if (this.SearchFilter.SeriesNum.length > 0) {
        query.seriesNum = this.SearchFilter.SeriesNum.join(',')
      }
      if (this.SearchFilter.SKU.length > 0) {
        query.sku = this.SearchFilter.SKU.join(',')
      }
      if (this.SearchFilter.Tags.length > 0) {
        query.tags = this.SearchFilter.Tags.join(',')
      }
      if (this.SearchFilter.Brand.length > 0) {
        query.brand = this.SearchFilter.Brand.join(',')
      }
      this.$router.replace({
        path: '/offerItems/gift',
        query
      }).then(() => {
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/delete',
        method: 'post',
        data: {
          seriesNum: this.ChooseIndex,
          taxonomy: 'Gift'
        }
      }).then(() => {
        this.Init()
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Init()
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Product/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.SeriesNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.BulkEditModel = false
      this.SearchFilter = {
        SelectCategories: [],
        SelectCategoriesName: '',
        SeriesNum: [],
        CustomSeriesNum: [],
        SKU: [],
        Tags: [],
        Brand: [],
        Name: '',
      }
      this.ChooseIndex = []
      this.$router.replace({
        path: '/offerItems/gift'
      }).then(() => {
        this.Init()
      })
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    GetCategories() {
      return this.$store.dispatch('InnerRequest', {
        url: '/product/category/list',
        method: 'get',
      }).then(({data}) => {
        this.CategoryList = data.list.map((category) => {
          return {label: category.Name, value: category.Slug}
        })
      }).catch((err) => {
        throw err
      })
    },
    Duplicate(SeriesNum) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/duplicate',
        method: 'get',
        params: {
          seriesNum: SeriesNum,
          taxonomy: 'Gift'
        }
      }).then(() => {
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/SuccessCopy'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Product/FailCopy') + error.msg,
          type: 'error'
        })
      })
    },
    Export(type = 'GoogleSheets') {
      switch (type) {
        case 'GoogleSheets':
          this.Component = () => import('@/components/ExportToGoogleSheet')
          setTimeout(() => {
            this.ToggleModal = true
          }, 500)
          break
        default:
          this.Loading = true
          this.$Progress.start()
          return this.$store.dispatch('InnerRequest', {
            endPoint: '/import',
            url: '/product/export/csv',
            method: 'post',
            data: {
              Taxonomy: 'Gift'
            }
          }).then(({ data }) => {
            this.Loading = false
            this.$Progress.finish()
            const BinaryString = window.atob(data)
            const Bytes = new Uint8Array(BinaryString.length)
            for (let index = 0; index < BinaryString.length; index++) {
              Bytes[index] = BinaryString.charCodeAt(index)
            }
            const Data = new Blob([Bytes], {type: 'application/octet-stream'})
            const blobURL = URL.createObjectURL(Data)
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = blobURL
            tempLink.setAttribute('download', `贈品列表-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
            window.URL.revokeObjectURL(blobURL)
          }).catch((err) => {
            this.Submit = false
            this.$Progress.fail()
            this.$notify({
              group: 'notify',
              title: this.$t('Message.Error'),
              text: this.$t('Message.Global/ErrorMessage') + err.msg,
              type: 'error'
            })
            throw err
          })
      }
    },
    ImportFromCurrent() {
      this.ProductListModel = true
    },
    async ChooseProduct(items) {
      if (items.length > 0) {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Info'),
          text: '新增商品中，請稍候...',
          type: 'info'
        })
        try {
          this.Submit = true
          this.Loading = true
          for (const item of items) {
            await this.$store.dispatch('InnerRequest', {
              url: '/product/add',
              method: 'post',
              data: {
                ...item,
                Taxonomy: 'Gift',
                OnlineTime: 0,
                OfflineTime: 2147483647,
              }
            })
          }
          await this.GetList()
          this.Submit = false
          this.Loading = false
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Success'),
            text: this.$t('Message.Product/AddSuccess'),
            type: 'success'
          })
        } catch (error) {
          this.Submit = false
          this.Loading = false
          console.log(error)
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Product/SaveFail-2') + error.msg,
            type: 'error'
          })
        }
      }
    },
    CloseProductListModel() {
      this.ProductListModel = false
    },
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#ProductList thead th:first-child {
  text-align: center;
}

#ProductList tbody td:first-child {
  vertical-align: middle;
}
</style>
