<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Report/ProductCategory') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CButtonGroup class="mr-2">
              <CButton v-for="Type in ViewTypeOptions" :key="Type.value" size="sm" :color="(ViewType === Type.value ? 'danger' : 'light')"  @click="ViewType = Type.value">{{ Type.label }}</CButton>
            </CButtonGroup>
            <CDropdown size="sm" color="info" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Report.Action') }}
              </template>
              <CDropdownHeader>{{ $t('Report.PrintCSV') }}</CDropdownHeader>
              <template v-for="(item, key) in SortStatisticsOptions">
                <CDropdownItem @click="Print(item)" :key="key">
                  {{ item.label }}
                </CDropdownItem>
              </template>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Site')">
              商店: {{ SearchFilter.Site.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton v-if="SearchFilter.Site.map(item => item.value).includes($store.state.user.permission.Project)" size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Origin')">
              結帳來源頁面: {{ SearchFilter.Origin.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Country')">
              {{ $t('Order.Country') }}: {{ SearchFilter.Country.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody v-if="ViewType === 'Table'" class="p-0">
        <CDataTable
          id="ProductReport"
          :fields="Field"
          :items="Reporting"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #Cover="{item}">
            <td>
              <img v-lazy="item.Cover" width="40" height="40" />
            </td>
          </template>
          <template #Rank="{index}">
            <td>
              #{{ index + 1 }}
            </td>
          </template>
          <template #Name="{item}">
            <td>
              {{ item.Name || '--' }}
            </td>
          </template>
          <template #TotalItems="{item}">
            <td>
              {{ item.TotalItems }} ({{ Math.round(item.TotalItems / TotalStatistics.TotalItems * 1000) / 10 }}%)
            </td>
          </template>
          <template #TotalDiscountActualAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ Math.round(item.TotalDiscountActualAmount) }} ({{ Math.round(item.TotalDiscountActualAmount / TotalStatistics.TotalDiscountActualAmount * 1000) / 10 }}%)
            </td>
          </template>
          <template #TotalActualAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ item.TotalActualAmount }} ({{ Math.round(item.TotalActualAmount / TotalStatistics.TotalActualAmount * 1000) / 10}}%)
            </td>
          </template>
          <template #TotalCostAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ item.TotalCostAmount }} ({{ Math.round((item.TotalCostAmount / TotalStatistics.TotalCostAmount * 1000) / 10 || '0') }}%)
            </td>
          </template>
          <template #TotalProfitAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ Math.round(item.TotalProfitAmount) }} ({{ Math.round(item.TotalProfitAmount / TotalStatistics.TotalProfitAmount * 1000) / 10 }}%)
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <template v-if="ViewType === 'Chart'">
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6" />
            <CCol col="6" class="text-md-right">
              <CButtonGroup class="mr-2">
                <CButton v-for="Type in SortStatisticsOptions" :key="Type.value" size="sm" :color="(SortStatistics === Type.value ? 'success' : 'light')"  @click="SortStatistics = Type.value">{{ Type.label }}</CButton>
              </CButtonGroup>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <div v-for="(Data, Index) in SortReporting" class="progress-group" :key="Data._id">
            <div class="progress-group-header">
              <span class="title font-weight-bold mb-1">#{{ Index + 1 }}{{ Data.Name }}</span>
              <span class="ml-auto font-weight-bold"><span v-if="SortStatistics !== 'TotalItems'">{{ $store.state.currency }}$</span>{{ Data[SortStatistics] }} <span class="text-muted small">({{ Math.round(Data[SortStatistics] / TotalStatistics[SortStatistics] * 1000) / 10 || 0 }}%)</span></span>
            </div>
            <div class="progress-group-bars">
              <CProgress class="progress-sm" :value="(Math.round(Data[SortStatistics] / TotalStatistics[SortStatistics] * 1000) / 10)" color="success"
              />
            </div>
          </div>
        </CCardBody>
      </template>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Taxonomy'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          商品類型
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Taxonomy"
            v-model="SearchFilter.Taxonomy"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            label="label"
            track-by="value"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="TaxonomyOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Site'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Site') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Site"
            v-model="SearchFilter.Site"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="$store.getters.storeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Origin'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Origin') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Origin"
            v-model="SearchFilter.Origin"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="PageList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Country'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Country') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Country"
            v-model="SearchFilter.Country"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="CountryOptions"
            :multiple="true"
            :taggable="true"
            @select="ChangeCountry"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_id')" type="text" v-model="SearchFilter.utm_id" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_source')" type="text" v-model="SearchFilter.utm_source" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_medium')" type="text" v-model="SearchFilter.utm_medium" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_campaign')" type="text" v-model="SearchFilter.utm_campaign" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_term')" type="text" v-model="SearchFilter.utm_term" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_content')" type="text" v-model="SearchFilter.utm_content" horizontal />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="Init()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "分類統計"
}
}
</route>

<script>
export default {
  name: 'ProductReportRank',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Loading: false,
      FilterModel: false,
      Submit: false,
      ShowFilter: 'All',
      SearchFilter: {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01 00:00:00')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD 23:59:59')).unix() * 1000,
        Site: [
          {
            value: (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project),
            label: '網路商店'
          }
        ],
        Origin: [],
        Country: [],
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
      },
      Reporting: [],
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      },
      TotalStatistics: {},
      ViewType: 'Table',
      ViewTypeOptions: [
        {
          value: 'Table',
          label: '表格'
        },
        {
          value: 'Chart',
          label: '圖表'
        }
      ],
      SortStatistics: 'TotalItems',
      SortStatisticsOptions: [
        {
          value: 'TotalItems',
          label: '商品銷售總數'
        },
        {
          value: 'TotalActualAmount',
          label: '商品銷售額'
        },
        {
          value: 'TotalDiscountActualAmount',
          label: '商品銷售額(含會員折扣)'
        },
        {
          value: 'TotalCostAmount',
          label: '商品總成本'
        },
        {
          value: 'TotalProfitAmount',
          label: '商品利潤'
        }
      ],
      BestSaleItems: {},
      BestSaleAmountItems: {},
      BestSaleProfitAmountItems: {},
      CategoryList: []
    }
  },
  computed: {
    PageList() {
      return this.$store.state.landingPageList || []
    },
    Field() {
      return [
        {key: 'Cover', label: ''},
        {key: 'Rank', label: '排名'},
        {key: 'Name', label: '分類名稱'},
        {key: 'TotalItems', label: '商品銷售總數(%)'},
        {key: 'TotalActualAmount', label: '商品銷售額(%)'},
        {key: 'TotalDiscountActualAmount', label: '商品銷售額-含會員折扣(%)'},
        {key: 'TotalCostAmount', label: '商品總成本(%)'},
        {key: 'TotalProfitAmount', label: '商品利潤(%)'}
      ]
    },
    TaxonomyOptions() {
      return Object.keys(this.$t('Product.TaxonomyOptions')).map(type => {
        return {
          value: type,
          label: this.$t('Product.TaxonomyOptions')[type]
        }
      })
    },
    SortReporting() {
      const Data = this.Reporting || []
      return Data.sort((a, b) => b[this.SortStatistics] - a[this.SortStatistics])
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(value => {
        return {
          value,
          label: this.$t('Logistics.CountryOptions')[value]
        }
      })
    }
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.$Progress.finish()
    }).catch(() => {
      this.$Progress.fail()
    })
  },
  methods: {
    Init() {
      this.FilterModel = false
      return Promise.all([
        this.GetCategories(),
        this.GetReporting()
      ]).then((result) => {
        const [categoryList, reportData] = result
        const [Report, TotalProductStatistics] = reportData
        this.TotalStatistics = TotalProductStatistics
        this.Reporting = Report.map(item => {
          item.Category = categoryList.find(category => category.value === item._id) || {
            label: item._id,
            value: item._id
          }
          if (item._id === null) {
            item.Category = {
              label: '未分類',
              value: 'uncategorized'
            }
          }
          item.Name = `${item.Category.label}(${item.Category.value})`
          item.Cover = item.Category.Cover
          return item
        })
        this.Loading = false
        return true
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    GetReporting() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000
      }
      if (this.SearchFilter.Site.length > 0) {
        Data.Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const Site = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
        this.SearchFilter.Site = [
          {
            value: Site,
            label: '網路商店'
          }
        ]
        Data.Site = Site
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return Promise.all([
        this.$store.dispatch('InnerRequest', {
          endPoint: '/service',
          url: '/order/reporting/category',
          method: 'post',
          data: {
            Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
            ...Data
          },
          headers: {
            'id-token': localStorage.getItem('XX-CSRF-Token')
          }
        }).then(({data}) => {
          return data.Data
          // this.Reporting = data.Data
        }),
        this.$store.dispatch('InnerRequest', {
          endPoint: '/service',
          url: '/order/reporting/product',
          method: 'post',
          data: {
            Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project,
            ...Data
          },
          headers: {
            'id-token': localStorage.getItem('XX-CSRF-Token')
          }
        }).then(({data}) => {
          return data.TotalProductStatistics
          // this.TotalStatistics = data.TotalProductStatistics
        })
      ])
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    ChangeCountry(newTag, id) {
      id = id.replace('SearchFilter.', '')
      const TaiwanCode = ['TW', 'TW-MAIN', 'TW-OUTLYING']
      if (TaiwanCode.includes(newTag.value)) {
        TaiwanCode.splice(TaiwanCode.findIndex(code => code === newTag.value), 1)
        TaiwanCode.forEach((code) => {
          this.SearchFilter[id].push({
            label: this.$t('Logistics.CountryOptions')[code],
            value: code
          })
        })
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01')).unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        Site: [
          {
            value: (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project),
            label: '網路商店'
          }
        ],
        Taxonomy: [],
        Origin: [],
        Country: []
      }
      this.Init()
    },
    GetUser(value, type = 'email') {
      const SendData = {}
      switch (type) {
        case 'email':
        case 'userId':
        case 'phoneNumber':
          SendData[type] = value
          break
        default:
          throw 'Wrong type.'
      }
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/user/get',
        method: 'post',
        data: SendData
      }).then(({ data }) => {
        this.Loading = false
        return data
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + ': ' + error.msg.message || error.msg,
          type: (error.type ? error.type : 'error')
        })
        throw error
      })
    },
    GetCategories () {
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/service',
        url: 'category/availableList',
        method: 'post',
        data: {
          Project: this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project
        },
        headers: {
          'id-token': localStorage.getItem('XX-CSRF-Token')
        }
      }).then(({data}) => {
        console.log(data.Data)
        return data.Data.map((category) => {
          return {
            ...category,
            label: category.Name,
            value: category.Slug
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    Print(SortParam) {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000
      }
      if (this.SearchFilter.Site.length > 0) {
        Data.Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const Site = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
        this.SearchFilter.Site = [
          {
            value: Site,
            label: '網路商店'
          }
        ]
        Data.Site = Site
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/service/exportReportCSV',
        method: 'post',
        data: {
          URL: '/order/reporting/category',
          Data,
          type: 'category',
          SortParam: SortParam.value
        }
      }).then((response) => {
        this.Loading = false
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `商品銷量報表-分類統計(${SortParam.label})${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    }
  }
}
</script>

<style lang="scss">
#ProductReport thead th:first-child {
  text-align: center;
}

#ProductReport tbody td:first-child {
  vertical-align: middle;
}

.WidgetList {
  .card-body {
    small {
      height: 36px;
      max-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
