<template>
  <section>
    <CContainer>
      <CForm @submit.stop.prevent="Save()">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol md="8" lg="9">
                <h5>{{ $t('Navigation.Offer/' + Action + 'ExtraItems') }}</h5>
                <hr>
                <div class="mb-5">
                  <CInput v-model="Data.Name" :label="$t('Offer.ActivityName')" :placeholder="$t('Offer.ActivityName')" type="text" />
                  <CTextarea v-model="Data.Description" :label="$t('Offer.ActivityDescription')" :placeholder="$t('Offer.ActivityDescription')" rows="3" />
                </div>
                <h5>{{ $t('Offer.AddonSetting') }}</h5>
                <hr>
                <div class="mb-5">
                  <CSelect :label="$t('Offer.ShowAreaTarget')" :options="TargetOptions" v-model="Data.Offer.Target" :value.sync="Data.Offer.Target" @change="ResetCondition" :placeholder="$t('Global.PleaseSelect')"/>
                  <CAlert v-if="Data.Offer.Target === 'SpecifyProductAddon'" color="info" :fade="false">
                    <i class="fas fa-info-circle mr-2" />此條件暫時僅有門市可使用此功能。
                  </CAlert>
                  <div v-if="Data.Offer.Target === 'SpecifyProductAddon'" class="form-group">
                    <label class="d-block">僅限單選</label>
                    <CSwitch color="success" :checked.sync="Data.Variable.SingleSelectOption"/>
                  </div>
                  <div v-if="(Data.Offer.Target === 'SpecifyProduct' || Data.Offer.Target === 'SpecifyProductAddon' || Data.Offer.Target === 'ProductAmount' || Data.Offer.Target === 'ProductQuantity')" class="mb-3">
                    <div class="font-weight-bold d-flex justify-content-between mb-2">
                      <label>{{ $t('Offer.SelectItem') }}</label>
                      <CButton color="success" size="sm" variant="outline" v-c-tooltip="$t('Global.Add')" @click="OpenProductListModel('TargetItem')">
                        <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}
                      </CButton>
                    </div>
                    <CDataTable
                      :loading="Loading"
                      id="SelectItemList"
                      :items="Data.Offer.TargetItem"
                      :fields="TargetItemField"
                      :noItemsView="NoItemsView"
                      responsive
                      hover
                    >
                      <template #Cover="{item}">
                        <td>
                          <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                        </td>
                      </template>
                      <template #Action="{index}">
                        <td>
                          <CButton color="danger" size="sm" v-c-tooltip="$t('Global.Remove')" @click="RemoveTargetItems(index)">
                            <CIcon name="cil-trash" class="c-icon-custom-size" />
                          </CButton>
                        </td>
                      </template>
                    </CDataTable>
                  </div>
                  <div v-if="Data.Offer.Target === 'OrderAmount' || Data.Offer.Target === 'ProductAmount'" class="form-group">
                    <label class="d-block mb-3">{{ $t('Offer.Condition') }}</label>
                    <div v-for="(Item, Index) in Data.Condition" :key="Index" class="mb-2">
                      <CInput size="sm" v-model="Item.FullAmount" class="mb-0" :placeholder="$t('Offer.FullAmountPlaceHolder')" type="number">
                        <template #prepend-content>{{ $t('Offer.FullAmount') }} $</template>
                      </CInput>
                    </div>
                  </div>
                  <div v-if="Data.Offer.Target === 'OrderQuantity' || Data.Offer.Target === 'ProductQuantity'" class="form-group">
                    <label class="d-block mb-3">{{ $t('Offer.Condition') }}</label>
                    <div v-for="(Item, Index) in Data.Condition" :key="Index" class="mb-2">
                      <CInput size="sm" v-model="Item.Quantity" class="mb-0" :placeholder="$t('Offer.FullAmountPlaceHolder')" type="number">
                        <template #prepend-content>{{ $t('Offer.Quantity') }}</template>
                        <template #append-content>{{ $t('Offer.PCS') }}</template>
                      </CInput>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="font-weight-bold d-flex justify-content-between mb-2">
                      <label>{{ $t('Offer.SelectExtraItem') }}</label>
                      <CButton color="success" size="sm" variant="outline" v-c-tooltip="$t('Global.Add')" @click="OpenProductListModel('ExtraItems')">
                        <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}
                      </CButton>
                    </div>
                    <CDataTable
                      :loading="Loading"
                      id="SelectExtraItem"
                      :items="Data.Variable.AddonList"
                      :fields="TargetItemField"
                      :noItemsView="NoItemsView"
                      responsive
                      hover
                    >
                      <template #Cover="{item}">
                        <td>
                          <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
                        </td>
                      </template>
                      <template #Name="{item}">
                        <td>
                          {{ item.Name }}
                        </td>
                      </template>
                      <template #SeriesNum="{item}">
                        <td>
                          {{ item.SeriesNum }}
                        </td>
                      </template>
                      <template #Action="{index}">
                        <td>
                          <CButton color="danger" size="sm" v-c-tooltip="$t('Global.Remove')" @click="RemoveAddon(index)">
                            <CIcon name="cil-trash" class="c-icon-custom-size" />
                          </CButton>
                        </td>
                      </template>
                    </CDataTable>
                  </div>
                </div>
                <h5>{{ $t('Offer.OfferLimit') }}</h5>
                <hr>
                <div class="mb-4">
                  <div class="form-group">
                    <label class="d-block mb-3">{{ $t('Offer.UsagePage') }}</label>
                    <multiselect
                      v-model="Data.UsagePage"
                      :tag-placeholder="$t('Global.EnterToAdd')"
                      :placeholder="$t('Global.PleaseKeyIn')"
                      :deselectLabel="$t('Global.DeselectLabel')"
                      :selectedLabel="$t('Global.SelectedLabel')"
                      :options="PageList"
                      :optionHeight="34"
                      label="Name"
                      track-by="Slug"
                      :multiple="true"
                      @select="CheckSelectUsagePage"
                    >
                      <template #noOptions>
                        {{ $t('Global.NoOptions') }}
                      </template>
                    </multiselect>
                  </div>
                </div>
              </CCol>
              <CCol md="4" lg="3">
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.StartTime') }}</label>
                  <v-date-picker v-model="Data.StartTime" mode="dateTime" is24hr>
                    <template v-slot="{ inputValue, inputEvents }">
                      <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                        <template #append-content>
                          <CIcon name="cil-calendar"/>
                        </template>
                      </CInput>
                    </template>
                  </v-date-picker>
                </div>
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.EndTime') }}</label>
                  <v-date-picker v-model="Data.EndTime" mode="dateTime" is24hr>
                    <template v-slot="{ inputValue, inputEvents }">
                      <CInput placeholder="YYYY-MM-DD HH:mm:ss" :value="inputValue" v-on="inputEvents">
                        <template #append-content>
                          <CIcon name="cil-calendar"/>
                        </template>
                      </CInput>
                    </template>
                  </v-date-picker>
                </div>
                <div class="form-group">
                  <label class="d-block">{{ $t('Offer.Status') }}</label>
                  <CSwitch color="success" :checked.sync="StatusSwitch"/>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter class="text-right">
            <CButton color="success" type="submit">
              {{ Action === 'Edit' ? $t('Global.Update') : $t('Global.Add') }}
            </CButton>
          </CCardFooter>
          <CElementCover v-if="(Loading === true)" :opacity="0.75">
            <CSpinner color="success"/>
          </CElementCover>
        </CCard>
      </CForm>
    </CContainer>
    <component :is="ProductListModelComponent" :Single="false" :Toggle="ProductListModel" :ChooseProductType="ChooseProductType" @ChooseProduct="ChooseProduct" />
  </section>
</template>

<route>
{
  "meta": {
    "label": "編輯加價購 / 加購組合"
  }
}
</route>

<script>
export default {
  name: 'ActivityDetail',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data () {
    return {
      Action: 'Edit',
      Loading: false,
      StatusSwitch: true,
      TargetSearchLoading: false,
      ProductListModel: false,
      ProductListModelComponent: null,
      ChooseProductType: null,
      ChooseConditionIndex: null,
      PageList: [
        { Name: this.$t('Offer.AllUsagePage'), Slug: 'ALL' },
        { Name: this.$t('Offer.OfficialWeb'), Slug: 'OFFICIAL' }
      ],
      ShopSetting: {},
      NoItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      },
      TargetGroupList: [],
      Data: {
        Taxonomy: 'ExtraItems',
        Name: '',
        Description: '',
        Slug: '',
        Event: {
          Type: ''
        },
        Offer: {
          Target: 'Cart',
          TargetItem: [],
          DiscountType: 'ExtraItems',
          FreeLogisticsFee: false,
          FreeServiceFee: false
        },
        Condition: [
          {
            Value: '0',
            Quantity: '0',
            FullAmount: '0',
            ItemData: {
              _v: 0
            }
          }
        ],
        TargetGroup: {
          Type: 'ALL',
          List: []
        },
        UsagePage: [
          {
            Name: this.$t('Offer.AllUsagePage'),
            Slug: "ALL"
          }
        ],
        Variable: {
          AddonList: [],
          _v: 0
        },
        Status: 'Y',
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-DD 00:00:00')).unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'month').format('YYYY-MM-DD 23:59:59')).unix() * 1000
      }
    }
  },
  computed: {
    TargetItemField () {
      return [
        { key: 'Cover', label: this.$t('Product/List.Cover') },
        { key: 'Name', label: this.$t('Product/List.Name') },
        { key: 'SeriesNum', label: this.$t('Product/List.SeriesNum') },
        { key: 'Action', label: '' }
      ]
    },
    TargetOptions () {
      return Object.keys(this.$t('Offer.ExtraItemsTargetOptions')).map(value => {
        return {
          value,
          label: this.$t('Offer.ExtraItemsTargetOptions')[value]
        }
      })
    },
    ActivityTypeOptions () {
      return Object.keys(this.$t('Offer.ActivityTypeOptions')).map(value => {
        return {
          value,
          label: this.$t('Offer.ActivityTypeOptions')[value]
        }
      })
    }
  },
  created() {
    this.Action = (this.$route.name === 'extraItems-detail-add' ? 'Add' : 'Edit')
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: 'error'
      })
    })
  },
  methods: {
    Init() {
      const Execute = [
        this.GetShopSetting(),
        this.GetSinglePageList()
      ]
      if (this.Action === 'Edit') {
        Execute.push(
          this.GetData()
        )
      } else {
        this.Data.Slug = this.GenerateSlug()
      }
      return Promise.all(Execute).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GenerateSlug () {
      return Math.random().toString(36).substring(3, 8).toUpperCase()
    },
    Save() {
      if (this.Data.Name === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/NameEmpty'),
          type: 'warn'
        })
        return
      }
      if (this.Data.Slug === '') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Warning'),
          text: this.$t('Message.Offer/SlugEmpty'),
          type: 'warn'
        })
        return
      }
      let Action
      switch (this.Action) {
        case 'Edit':
          Action = this.Edit()
          break
        case 'Add':
          Action = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Offer/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Action.then((result) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
        if (this.Action === 'Add') {
          this.$router.replace('/offer/extraItems/detail/' + result.Data._id)
        }
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Offer/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/add',
          Data: {
            ...this.Data,
            StartTime: this.$dayjs(this.Data.StartTime).unix(),
            EndTime: this.$dayjs(this.Data.EndTime).unix(),
            Status: this.StatusSwitch === true ? 'Y' : 'N'
          }
        }
      }).then(({data}) => {
        return {
          ...data,
          msg: this.$t('Message.Offer/AddSuccess')
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      if (this.Data.Slug === '') {
        return Promise.reject({
          code: 403,
          msg: this.$t('Message.Offer/SlugEmpty')
        })
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/edit',
          Data: {
            Taxonomy: 'ExtraItems',
            ID: this.$route.params.id,
            UpdateData: {
              ...this.Data,
              StartTime: this.$dayjs(this.Data.StartTime).unix(),
              EndTime: this.$dayjs(this.Data.EndTime).unix(),
              Status: this.StatusSwitch === true ? 'Y' : 'N'
            }
          }
        }
      }).then(() => {
        return {
          msg: this.$t('Message.Offer/EditSuccess')
        }
      }).catch((err) => {
        throw err
      })
    },
    GetData() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/offer/manage/detail',
          Data: {
            Taxonomy: 'ExtraItems',
            ID: this.$route.params.id
          }
        }
      }).then(({data}) => {
        this.Loading = false
        this.Data = data.Data
        this.StatusSwitch = this.Data.Status === 'Y'
        delete this.Data._id
        delete this.Data.__v
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetSinglePageList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/page/shop/list',
        method: 'get',
        params: {
          page: 1,
          perPage: 60,
          order: 'nto'
        }
      }).then(({data}) => {
        this.Loading = false
        const SinglePage = data.list.map(item => {
          return {
            Name: `${this.$t('Offer.SalePage')}: ${item.Name} (${item.Slug})`,
            Slug: item.Slug
          }
        })
        this.PageList = [
          ...this.PageList,
          ...SinglePage
        ]
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetShopSetting () {
      return this.$store.dispatch('InnerRequest', {
        url: '/system/get/ShopSetting',
        method: 'post'
      }).then(({data}) => {
        this.ShopSetting = data
        if (this.ShopSetting.Location === true) {
          Object.keys(this.$t('Logistics.CountryOptions')).forEach(value => {
            this.PageList.push({
              Name: this.$t('Offer.OfficialWeb') + ': ' + this.$t('Logistics.CountryOptions')[value],
              Slug: 'Default-' + value
            })
          })
        }
        return true
      }).catch((err) => {
        throw err
      })
    },
    AddCondition() {
      this.Data.Condition.push({
        Value: 1,
        Quantity: null,
        FullAmount: null,
        ItemData: {}
      })
    },
    RemoveCondition(index) {
      this.Data.Condition.splice(index, 1)
    },
    RemoveAddon(index) {
      this.Data.Variable.AddonList.splice(index, 1)
    },
    CheckSelectUsagePage(selectedOption) {
      if (selectedOption.Slug === 'ALL') {
        this.Data.UsagePage.filter(item => item.Slug !== 'ALL').forEach(data => {
          this.Data.UsagePage.splice(this.Data.UsagePage.findIndex(item => item.Slug === data.Slug), 1)
        })
      } else if (this.Data.UsagePage.filter(item => item.Slug === 'ALL').length > 0) {
        this.Data.UsagePage.splice(this.Data.UsagePage.findIndex(item => item.Slug === 'ALL'), 1)
      }
    },
    OpenProductListModel(type, index = null) {
      this.ChooseProductType = type
      this.ChooseConditionIndex = index
      this.ProductListModel = true
      this.ProductListModelComponent = () => import('@/components/product/ProductList')
    },
    ChooseProduct(items) {
      items.filter(item => item.Status === 1).forEach(item => {
        const Data = {
          Cover: item.Cover,
          SeriesNum: item.SeriesNum,
          ID: item._id,
          Name: item.Name,
          HeaderText: item.HeaderText
        }
        switch (this.ChooseProductType) {
          case 'Default':
          case 'TargetItem':
            if (!this.Data.Variable.TargetItem || this.Data.Variable.TargetItem.length === 0) {
              this.$set(this.Data.Variable, 'TargetItem', [])
            }
            if (this.Data.Offer.TargetItem.filter(data => data.SeriesNum === Data.SeriesNum).length > 0) {
              this.$notify({
                group: 'notify',
                title: this.$t('Message.Info'),
                text: this.$t('Message.Offer/ChooseProductFail-2'),
                type: 'warn'
              })
              return false
            }
            this.Data.Offer.TargetItem.push(Data)
            break
          case 'ExtraItems':
            if (!this.Data.Variable.AddonList || this.Data.Variable.AddonList.length === 0) {
              this.$set(this.Data.Variable, 'AddonList', [])
            }
            if (this.Data.Variable.AddonList.filter(data => data.SeriesNum === Data.SeriesNum).length > 0) {
              this.$notify({
                group: 'notify',
                title: this.$t('Message.Info'),
                text: this.$t('Message.Offer/ChooseProductFail-2'),
                type: 'warn'
              })
              return false
            }
            this.Data.Variable.AddonList.push({
              ...Data,
              Variable: item.Variable,
              EnableOption: item.EnableOption,
              EnableStock: item.EnableStock,
              Stock: item.Stock,
              Amount: item.Amount,
              Status: item.Status
            })
            break
        }
      })
      if (items.filter(item => item.Status !== 1).length > 0 && this.ChooseProductType === 'ExtraItems') {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Info'),
          text: this.$t('Message.Offer/ChooseProductFail-3'),
          type: 'info'
        })
      }
      this.CloseProductListModel()
    },
    RemoveTargetItems(index) {
      this.Data.Offer.TargetItem.splice(index, 1)
    },
    CloseProductListModel() {
      this.ProductListModel = false
      this.ProductListModelComponent = null
    },
    ResetCondition() {
      this.Data.Condition = [
        {
          Value: '0',
          Quantity: '1',
          FullAmount: '0',
          ItemData: {
            _v: 0
          }
        }
      ]
      if (this.Action === 'Edit') {
        this.$swal({
          icon: 'info',
          title: '生效頁面條件已變更',
          text: '變更生效頁面條件時將重置優惠條件設定，若要放棄此變更請重新整理或回到上一頁。',
          showCancelButton: true,
          confirmButtonColor: '#2eb85c',
          confirmButtonText: this.$t('Global.Confirm'),
          cancelButtonText: this.$t('Global.Cancel')
        })
      }
    }
  }
}
</script>

<style lang="scss">
.popover-old {
  min-width: 225px;
}
</style>
