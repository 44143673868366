<template>
  <main>
    <CCard>
      <CCardHeader>
        <CRow class="d-flex align-items-center">
          <CCol col="6">
            <h5 class="mb-0">{{ $t('Navigation.Report/SKURank') }}</h5>
          </CCol>
          <CCol col="6" class="text-md-right">
            <CDropdown size="sm" color="info" placement="bottom-start" class="d-inline-block">
              <template #toggler-content>
                <CIcon size="sm" name="cil-applications-settings" class="mr-1" />{{ $t('Report.Action') }}
              </template>
              <CDropdownHeader>{{ $t('Report.PrintCSV') }}</CDropdownHeader>
              <template v-for="(item, key) in SortStatisticsOptions">
                <CDropdownItem @click="Print(item)" :key="key">
                  {{ item.label }}
                </CDropdownItem>
              </template>
            </CDropdown>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto px-2">
        <CRow class="text-nowrap">
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="OpenFilter('All')">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2 d-none d-md-inline-block" @click="OpenFilter('DataTimeRange')">
              {{ $t('Order.Filter.DataTime') }}: {{
                SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
              }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Taxonomy')">
              商品類型: {{ SearchFilter.Taxonomy.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Site')">
              商店: {{ ShowStoreLabel }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton v-if="SearchFilter.Site.map(item => item.value).includes($store.state.user.permission.Project)" size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Origin')">
              結帳來源頁面: {{ SearchFilter.Origin.map(item => item.label).join(',') || '所有' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButtonGroup class="mr-2">
              <CButton size="sm" shape="pill" class="px-2 pl-3" color="dark">顯示方式:</CButton>
              <CButton v-for="Type in ViewTypeOptions" class="px-2" shape="pill" :key="Type.value" size="sm" :color="(ViewType === Type.value ? 'info' : 'light')"  @click="ViewType = Type.value">{{ Type.label }}</CButton>
            </CButtonGroup>
            <CButton size="sm" color="danger" shape="pill" class="mr-2" @click="ResetState()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardHeader class="w-100 overflow-auto">
        <CRow class="WidgetList">
          <CCol col="6" lg="4">
            <CWidgetProgressIcon header="最高銷售量" :text="`${BestSaleItems.Name || '--'}(${BestSaleItems.TotalItems || '--'})`" color="gradient-success" inverse />
          </CCol>
          <CCol col="6" lg="4">
            <CWidgetProgressIcon header="最高銷售額" :text="`${BestSaleAmountItems.Name || '--'}(${$store.state.currency}$${$amount.format(BestSaleAmountItems.TotalActualAmount) || '--'})`" color="gradient-warning" inverse />
          </CCol>
          <CCol col="6" lg="4">
            <CWidgetProgressIcon header="獲利最高" :text="`${BestSaleProfitAmountItems.Name || '--'}(${$store.state.currency}$${$amount.format(BestSaleProfitAmountItems.TotalProfitAmount) || '--'})`" color="gradient-info" inverse />
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody v-if="ViewType === 'Table'" class="p-0">
        <CDataTable
          id="ProductReport"
          :fields="Field"
          :items="Reporting"
          :loading="Loading"
          :noItemsView="noItemsView"
          addTableClasses="small text-nowrap"
          responsive
          hover
        >
          <template #Cover="{item}">
            <td>
              <img v-lazy="SKU[item._id] && SKU[item._id].Cover || item.Cover" width="40" height="40" />
            </td>
          </template>
          <template #Rank="{index}">
            <td>
              #{{ index + 1 }}
            </td>
          </template>
          <template #Name="{item}">
            <td>
              <a href="javascript:void(0)" class="text-info" @click="GetProductDetailReport(item)">{{ SKU[item._id] && SKU[item._id].Name || item.Name }}</a>
            </td>
          </template>
          <template #SKU="{item}">
            <td>
              <a href="javascript:void(0)" class="text-info" @click="GetProductDetailReport(item)">{{ item._id }}</a>
            </td>
          </template>
          <template #TotalItems="{item}">
            <td>
              {{ item.TotalItems }} ({{ Math.round(item.TotalItems / TotalStatistics.TotalItems * 1000) / 10 }}%)
            </td>
          </template>
          <template #TotalDiscountActualAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(Math.round(item.TotalDiscountActualAmount)) }} ({{ Math.round(item.TotalDiscountActualAmount / TotalStatistics.TotalDiscountActualAmount * 1000) / 10 }}%)
            </td>
          </template>
          <template #TotalActualAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.TotalActualAmount) }} ({{ Math.round(item.TotalActualAmount / TotalStatistics.TotalActualAmount * 1000) / 10}}%)
            </td>
          </template>
          <template #TotalCostAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(item.TotalCostAmount) }} ({{ Math.round((item.TotalCostAmount / TotalStatistics.TotalCostAmount * 1000) / 10 || '0') }}%)
            </td>
          </template>
          <template #TotalProfitAmount="{item}">
            <td>
              {{ $store.state.currency }}${{ $amount.format(Math.round(item.TotalProfitAmount)) }} ({{ Math.round(item.TotalProfitAmount / TotalStatistics.TotalProfitAmount * 1000) / 10 }}%)
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <template v-if="ViewType === 'Chart'">
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6" />
            <CCol col="6" class="text-md-right">
              <CButtonGroup class="mr-2">
                <CButton v-for="Type in SortStatisticsOptions" :key="Type.value" size="sm" :color="(SortStatistics === Type.value ? 'success' : 'light')"  @click="SortStatistics = Type.value">{{ Type.label }}</CButton>
              </CButtonGroup>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <div v-for="(Data, Index) in SortReporting" class="progress-group" :key="Data._id">
            <div class="progress-group-header">
              <span class="title font-weight-bold mb-1">#{{ Index + 1 }}{{ Data.Name }}</span>
              <span class="ml-auto font-weight-bold"><span v-if="SortStatistics !== 'TotalItems'">{{ $store.state.currency }}$</span>{{ Data[SortStatistics] }} <span class="text-muted small">({{ Math.round(Data[SortStatistics] / TotalStatistics[SortStatistics] * 1000) / 10 || 0 }}%)</span></span>
            </div>
            <div class="progress-group-bars">
              <CProgress class="progress-sm" :value="(Math.round(Data[SortStatistics] / TotalStatistics[SortStatistics] * 1000) / 10)" color="success"
              />
            </div>
          </div>
        </CCardBody>
      </template>
    </CCard>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'DataTimeRange'" form class="form-group mb-0">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.StartTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.StartTime" mode="dateTime" is24hr :max-date="$dayjs(SearchFilter.EndTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Filter.EndTime') }}
        </CCol>
        <CCol sm="9">
          <v-date-picker v-model="SearchFilter.EndTime" mode="dateTime" is24hr :min-date="$dayjs(SearchFilter.StartTime || new Date).toISOString()">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput placeholder="YYYY-MM-DD HH:mm" :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-calendar"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Taxonomy'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          商品類型
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Taxonomy"
            v-model="SearchFilter.Taxonomy"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            label="label"
            track-by="value"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :selectLabel="$t('Global.SelectLabel')"
            :options="TaxonomyOptions"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Site'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Site') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Site"
            v-model="SearchFilter.Site"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="$store.getters.storeList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Origin'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Origin') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Origin"
            v-model="SearchFilter.Origin"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="PageList"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Country'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Order.Country') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Country"
            v-model="SearchFilter.Country"
            label="label"
            track-by="value"
            :searchable="false"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="CountryOptions"
            :multiple="true"
            :taggable="true"
            @select="ChangeCountry"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_id')" type="text" v-model="SearchFilter.utm_id" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_source')" type="text" v-model="SearchFilter.utm_source" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_medium')" type="text" v-model="SearchFilter.utm_medium" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_campaign')" type="text" v-model="SearchFilter.utm_campaign" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_term')" type="text" v-model="SearchFilter.utm_term" horizontal />
      <CInput v-if="ShowFilter === 'All'" :label="$t('Order.UTM.utm_content')" type="text" v-model="SearchFilter.utm_content" horizontal />
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="Init()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="ProductDetailModal" class="ActionModel" size="xl" :show.sync="ProductDetailModal" :centered="true">
      <div class="mb-4 d-flex justify-content-between">
        <div>
          <CButton size="sm" color="light" shape="pill" class="px-3 mr-2 d-none d-md-inline-block">
            <b>{{ $t('Order.Filter.DataTime') }}</b>: {{
              SearchFilter.StartTime ? $dayjs(SearchFilter.StartTime).format('YYYY-MM-DD HH:mm:ss') : '--'
            }} ~ {{ SearchFilter.EndTime ? $dayjs(SearchFilter.EndTime).format('YYYY-MM-DD HH:mm:ss') : '--' }}
          </CButton>
          <CButton size="sm" color="light" shape="pill" class="px-3 mr-2">
            <b>商店</b>: {{ ShowStoreLabel }}
          </CButton>
          <CButton v-if="SearchFilter.Site.map(item => item.value).includes($store.state.user.permission.Project)" size="sm" color="light" shape="pill" class="px-3 mr-2">
            <b>結帳來源頁面</b>: {{ SearchFilter.Origin.map(item => item.label).join(',') || '所有' }}
          </CButton>
        </div>
        <CButtonGroup>
          <CButton size="sm" shape="pill" class="px-2 pl-3 font-weight-bold" color="light">分組依據:</CButton>
          <CButton size="sm" shape="pill" class="px-2" :color="(ProductDetailGroupType === 'CreateTime' ? 'info' : 'light')"  @click="ProductDetailGroupType = 'CreateTime';GetProductDetailReport({_id: ProductDetail.SKU})">建立時間</CButton>
          <CButton size="sm" shape="pill" class="px-2" :color="(ProductDetailGroupType === 'ReceiverDate' ? 'info' : 'light')"  @click="ProductDetailGroupType = 'ReceiverDate';GetProductDetailReport({_id: ProductDetail.SKU})">預計出貨日</CButton>
          <CButton size="sm" shape="pill" class="px-2 pr-3" :color="(ProductDetailGroupType === 'Site' ? 'info' : 'light')"  @click="ProductDetailGroupType = 'Site';GetProductDetailReport({_id: ProductDetail.SKU})">商店</CButton>
        </CButtonGroup>
      </div>
      <div class="w-100 overflow-auto">
        <CRow class="text-nowrap mb-3" style="min-width: 900px;">
          <CCol col>
            <CCallout color="success" class="my-1">
              <small class="text-muted">商品銷售總數</small><br>
              <strong class="h4">{{ ProductDetail.TotalStatistics.TotalItems || '0' }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">商品銷售總計</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(ProductDetail.TotalStatistics.TotalActualAmount || 0)}}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="warning" class="my-1">
              <small class="text-muted">商品銷售總計(含會員折扣)</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(ProductDetail.TotalStatistics.TotalDiscountActualAmount || 0)}}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">商品總成本</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(ProductDetail.TotalStatistics.TotalCostAmount || 0) }}</strong>
            </CCallout>
          </CCol>
          <CCol col>
            <CCallout color="info" class="my-1">
              <small class="text-muted">商品總利潤</small><br>
              <strong class="h4">{{ $store.state.currency + '$' }}{{ $amount.format(ProductDetail.TotalStatistics.TotalProfitAmount || 0) }}</strong>
            </CCallout>
          </CCol>
        </CRow>
      </div>
      <CDataTable
        id="OrderHistory"
        addTableClasses="small text-nowrap"
        :items="ProductDetail.List"
        :fields="ProductDetailField"
        :loading="Loading"
        :noItemsView="{noResults: $t('Global.NoResults'),noItems: $t('Global.NoItems')}"
        responsive
        hover
        striped
      >
        <template #Date="{item}">
          <td>
            {{ item._id }}
          </td>
        </template>
        <template #TotalItems="{item}">
          <td>
            {{ item.TotalItems }}
          </td>
        </template>
        <template #TotalDiscountActualAmount="{item}">
          <td>
            {{ $store.state.currency }}${{ $amount.format(Math.round(item.TotalDiscountActualAmount)) }}
          </td>
        </template>
        <template #TotalActualAmount="{item}">
          <td>
            {{ $store.state.currency }}${{ $amount.format(item.TotalActualAmount) }}
          </td>
        </template>
        <template #TotalCostAmount="{item}">
          <td>
            {{ $store.state.currency }}${{ $amount.format(item.TotalCostAmount) }}
          </td>
        </template>
        <template #TotalProfitAmount="{item}">
          <td>
            {{ $store.state.currency }}${{ $amount.format(Math.round(item.TotalProfitAmount)) }}
          </td>
        </template>
      </CDataTable>
      <CPagination :activePage.sync="ProductDetail.Pagination.Page" :pages.sync="ProductDetail.Pagination.TotalPages" @update:activePage="GetProductDetailReport({_id: ProductDetail.SKU}, true)"/>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">商品銷售報表: {{ ProductDetail.Name || '--' }} ({{ ProductDetail.SKU || '--' }})</h5>
          <CButtonClose @click="ProductDetailModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#ProductDetailModal' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="ExportProductDetailReport" color="info" :disabled="Loading">{{ $t('Global.ExportToCSV') }}</CButton>
          <CButton @click="ProductDetailModal = false" color="light">{{ $t('Global.Close') }}</CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "SKU排行"
}
}
</route>

<script>
export default {
  name: 'ProductReportRank',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect')
  },
  data() {
    return {
      Loading: false,
      FilterModel: false,
      ProductDetailModal: false,
      ProductDetailGroupType: 'CreateTime',
      ProductDetailGroupTypeName: {
        CreateTime: '建立時間',
        ReceiverDate: '預計出貨日',
        Site: '商店',
      },
      Submit: false,
      ShowFilter: 'All',
      SearchFilter: {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01 00:00:00')).subtract(1, 'month').unix() * 1000,
        EndTime: this.$dayjs(this.$dayjs().add(1, 'day').format('YYYY-MM-DD 23:59:59')).unix() * 1000,
        Site: [
          {
            value: (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project),
            label: '網路商店'
          }
        ],
        Taxonomy: [],
        Origin: [],
        Country: [],
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: ''
      },
      SKU: {},
      Reporting: [],
      ProductDetail: {
        SKU: null,
        Name: null,
        List: [],
        Pagination: {},
        TotalStatistics: {}
      },
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      },
      TotalStatistics: {},
      ViewType: 'Table',
      ViewTypeOptions: [
        {
          value: 'Table',
          label: '表格'
        },
        {
          value: 'Chart',
          label: '圖表'
        }
      ],
      SortStatistics: 'TotalItems',
      SortStatisticsOptions: [
        {
          value: 'TotalItems',
          label: '商品銷售總數'
        },
        {
          value: 'TotalActualAmount',
          label: '商品銷售額'
        },
        {
          value: 'TotalDiscountActualAmount',
          label: '商品銷售額(含會員折扣)'
        },
        {
          value: 'TotalCostAmount',
          label: '商品總成本'
        },
        {
          value: 'TotalProfitAmount',
          label: '商品利潤'
        }
      ],
      BestSaleItems: {},
      BestSaleAmountItems: {},
      BestSaleProfitAmountItems: {}
    }
  },
  computed: {
    PageList() {
      return this.$store.state.landingPageList || []
    },
    Field() {
      return [
        {key: 'Cover', label: ''},
        {key: 'Rank', label: '排名'},
        {key: 'Name', label: 'SKU名稱'},
        {key: 'SKU', label: this.$t('Product/Detail.Stock/SKU')},
        {key: 'TotalItems', label: '商品銷售總數(%)'},
        {key: 'TotalActualAmount', label: '商品銷售額(%)'},
        {key: 'TotalDiscountActualAmount', label: '商品銷售額-含會員折扣(%)'},
        {key: 'TotalCostAmount', label: '商品總成本(%)'},
        {key: 'TotalProfitAmount', label: '商品利潤(%)'}
      ]
    },
    ProductDetailField() {
      return [
        {key: 'Date', label: this.ProductDetailGroupTypeName[this.ProductDetailGroupType]},
        {key: 'TotalItems', label: '銷售總數'},
        {key: 'TotalActualAmount', label: '商品銷售額'},
        {key: 'TotalDiscountActualAmount', label: '商品銷售額-含會員折扣'},
        {key: 'TotalCostAmount', label: '商品總成本'},
        {key: 'TotalProfitAmount', label: '商品利潤'}
      ]
    },
    TaxonomyOptions() {
      return Object.keys(this.$t('Product.TaxonomyOptions')).map(type => {
        return {
          value: type,
          label: this.$t('Product.TaxonomyOptions')[type]
        }
      })
    },
    SortReporting() {
      const Data = this.Reporting || []
      return Data.sort((a, b) => b[this.SortStatistics] - a[this.SortStatistics])
    },
    CountryOptions() {
      return Object.keys(this.$t('Logistics.CountryOptions')).map(value => {
        return {
          value,
          label: this.$t('Logistics.CountryOptions')[value]
        }
      })
    },
    StoreList() {
      return this.$store.state.storeList.filter(item => item.value !== 'Default') || []
    },
    ShowStoreLabel() {
      if (this.SearchFilter.Site.length > 2) {
        return `已選取 ${this.SearchFilter.Site.length} 間商店`
      }
      return this.SearchFilter.Site.map(item => item.label).join(',') || '所有'
    }
  },
  mounted() {
    this.$Progress.start()
    return this.Init().then(() => {
      this.$Progress.finish()
    }).catch(() => {
      this.$Progress.fail()
    })
  },
  methods: {
    Init() {
      this.FilterModel = false
      return Promise.all([
        this.GetReporting()
      ]).then(() => {
        return true
      }).catch((err) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: (err.type ? err.type : 'error')
        })
      })
    },
    GetReporting() {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000,
        TaxonomyMode: 'SKU',
      }
      if (this.SearchFilter.Site.length > 0) {
        Data.Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const Site = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
        this.SearchFilter.Site = [
          {
            value: Site,
            label: '網路商店'
          }
        ]
        Data.Site = Site
      }
      if (this.SearchFilter.Taxonomy.length > 0) {
        Data['ProductInfo.Taxonomy'] = this.SearchFilter.Taxonomy.map(item => item.value)
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/reporting/product',
          Data
        }
      }).then(({data}) => {
        if (data.Data.length === 0) {
          this.Reporting = []
          return true
        }
        data.Data = data.Data.filter(item => !!item._id)
        this.Loading = false
        this.SKU = data.SKU
        this.TotalStatistics = data.TotalProductStatistics
        this.BestSaleItems = {
          ...data.Data[0],
          Name: data.SKU[data.Data[0]._id] && data.SKU[data.Data[0]._id].Name || data.Data[0].Name
        }
        this.BestSaleAmountItems = Object.assign([], data.Data).sort((a, b) => (b.TotalActualAmount || 0) - (a.TotalActualAmount || 0))[0] || {}
        this.BestSaleAmountItems.Name = data.SKU[this.BestSaleAmountItems._id] && data.SKU[this.BestSaleAmountItems._id].Name || this.BestSaleAmountItems.Name
        this.BestSaleProfitAmountItems = Object.assign([], data.Data).sort((a, b) => (b.TotalProfitAmount || 0) - (a.TotalProfitAmount || 0))[0] || {}
        this.BestSaleProfitAmountItems.Name = data.SKU[this.BestSaleProfitAmountItems._id] && data.SKU[this.BestSaleProfitAmountItems._id].Name || this.BestSaleProfitAmountItems.Name
        this.Reporting =  Object.assign([], data.Data)
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        throw err
      })
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    ChangeCountry(newTag, id) {
      id = id.replace('SearchFilter.', '')
      const TaiwanCode = ['TW', 'TW-MAIN', 'TW-OUTLYING']
      if (TaiwanCode.includes(newTag.value)) {
        TaiwanCode.splice(TaiwanCode.findIndex(code => code === newTag.value), 1)
        TaiwanCode.forEach((code) => {
          this.SearchFilter[id].push({
            label: this.$t('Logistics.CountryOptions')[code],
            value: code
          })
        })
      }
    },
    ResetState() {
      this.Submit = false
      this.AlertModel = false
      this.SearchFilter = {
        StartTime: this.$dayjs(this.$dayjs().format('YYYY-MM-01')).unix() * 1000,
        EndTime: this.$dayjs().unix() * 1000,
        Site: [
          {
            value: (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project),
            label: '網路商店'
          }
        ],
        Taxonomy: [],
        Origin: [],
        Country: []
      }
      this.Init()
    },
    Print(SortParam) {
      this.Loading = true
      this.FilterModel = false
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000,
        TaxonomyMode: 'SKU'
      }
      if (this.SearchFilter.Site.length > 0) {
        Data.Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const Site = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
        this.SearchFilter.Site = [
          {
            value: Site,
            label: '網路商店'
          }
        ]
        Data.Site = Site
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      if (this.SearchFilter.Country.length > 0) {
        Data['MemberInfo.Country'] = this.SearchFilter.Country.map(item => item.value)
      }
      const UTMParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
      UTMParams.forEach((item) => {
        if (this.SearchFilter[item] !== '') Data[`Variable.UTM.${item}`] = this.SearchFilter[item]
      })
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/service/exportReportCSV',
        method: 'post',
        data: {
          URL: '/order/reporting/product',
          Data,
          type: 'sku',
          SortParam: SortParam.value
        }
      }).then((response) => {
        this.Loading = false
        const BinaryString = window.atob(response.data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `SKU銷量報表-商品排行(${SortParam.label})${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetProductDetailReport (item, changePage = false) {
      const project = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
      this.ProductDetailModal = true
      this.Loading = true
      if (!changePage) {
        this.ProductDetail = {
          SKU: item._id || this.SKU,
          Name: this.SKU[item._id] && this.SKU[item._id].Name || item.Name || this.Name,
          Pagination: {
            Page: 1,
            PerPage: 10,
            TotalItems: 0,
            TotalPages: 0
          },
          List: [],
          TotalStatistics: {}
        }
      }
      const Data = {
        StartTime: this.SearchFilter.StartTime / 1000,
        EndTime: this.SearchFilter.EndTime / 1000,
        'ProductInfo.Stock.SKU': item._id,
        Page: this.ProductDetail.Pagination.Page,
        GroupType: this.ProductDetailGroupType
      }
      if (this.SearchFilter.Site.length > 0) {
        Data.Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const Site = project
        this.SearchFilter.Site = [
          {
            value: Site,
            label: '網路商店'
          }
        ]
        Data.Site = Site
      }
      if (this.SearchFilter.Origin.length > 0) {
        Data['Variable.CartType'] = this.SearchFilter.Origin.map(item => item.value)
      }
      return this.$store.dispatch('InnerRequest', {
        url: '/service/action',
        method: 'post',
        data: {
          URL: '/order/reporting/product/detail',
          Data
        }
      }).then(({ data }) => {
        this.Loading = false
        this.ProductDetail.Pagination = data.Pagination
        this.ProductDetail.List = data.Data.map(item => {
          // 商店資料
          if (this.ProductDetailGroupType === 'Site') {
            item.StoreData = this.StoreList.find(page => page.value === item._id) || {
              label: item._id === project ? '網路商店' : item._id.replace('POS-', '').replace('MENU-', ''),
              value: item._id.replace('POS-', '').replace('MENU-', '')
            }
            item._id = item.StoreData.label
          }
          return item
        })
        this.ProductDetail.TotalStatistics = data.TotalStatistics
      }).catch((error) => {
        this.Loading = false
        throw error
      })
    },
    ExportProductDetailReport() {
      this.Loading = true
      let Site
      if (this.SearchFilter.Site.length > 0) {
        Site = this.SearchFilter.Site.map(item => item.value)
      } else if (this.SearchFilter.Site.length === 0) {
        const CurrentSite = (this.$store.state.user.permission.Project || JSON.parse(localStorage.getItem('Permission')).Project)
        this.SearchFilter.Site = [
          {
            value: CurrentSite,
            label: '網路商店'
          }
        ]
        Site = CurrentSite
      }
      let CartType
      if (this.SearchFilter.Origin.length > 0) {
        CartType = this.SearchFilter.Origin.map(item => item.value)
      }
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url: '/report/export/product/detail',
        method: 'post',
        data: {
          StartTime: this.SearchFilter.StartTime / 1000,
          EndTime: this.SearchFilter.EndTime / 1000,
          SKU: this.ProductDetail.SKU,
          Site,
          CartType
        }
      }).then(({ data }) => {
        this.Loading = false
        const BinaryString = window.atob(data)
        const Bytes = new Uint8Array(BinaryString.length)
        for (let index = 0; index < BinaryString.length; index++) {
          Bytes[index] = BinaryString.charCodeAt(index)
        }
        const Data = new Blob([Bytes], {type: 'application/octet-stream'})
        const blobURL = URL.createObjectURL(Data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `${this.ProductDetail.Name}-${this.ProductDetail.SKU}-${this.$dayjs().format('YYYYMMDD')}.csv`, '_blank')
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
  }
}
</script>

<style lang="scss">
#ProductReport thead th:first-child {
  text-align: center;
}

#ProductReport tbody td:first-child {
  vertical-align: middle;
}

.WidgetList {
  .card-body {
    small {
      height: 36px;
      max-height: 36px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
